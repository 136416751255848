@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* --costimize_site_color: #433f3f; */
  --costimize_site_color: #141416;
  --costimize_background_color: #0f0f0f;
  --costimize_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --orange-color: #ff6a2f;
}

* {
  font-family: "Poppins" !important;
}

.white-color {
  color: #ffffff !important;
}

.site-color {
  color: var(--costimize_site_color);
}

.plat-text-center {
  text-align: center !important;
}

.plat-text-left {
  text-align: left;
}

.plat-text-right {
  text-align: right;
}

.plat-padding-right-1 {
  padding-right: 1rem;
}

.costimize_bg {
  background-color: var(--costimize_background_color);
  height: 100% !important;
}

.danger-color {
  color: #be1e2d;
}

.success-color {
  color: #22bb33;
}

.costimize-loader .ant-spin .ant-spin-dot-item {
  background-color: var(--orange-color) !important;
  /* background-color: var(--costimize_site_color) !important; */
}
.ant-spin .ant-spin-dot-item {
  background-color: var(--orange-color) !important;
  /* background-color: var(--costimize_site_color) !important; */
}
.assets_spend_screen {
  margin-top: 5rem;
  margin-left: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}

.costimize-body-screen {
  margin-top: 7.5rem;
  margin-left: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}
.costimize-body-screen-quick {
  padding: 0px 0.3rem 0.3rem 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}
.costimize-header-body-quick {
  width: 100%;
  padding: 0 0.9rem 0 2.3rem;
  z-index: 11;
  background-color: var(--costimize_background_color);
}
.text-small {
  font-size: 0.7rem;
  position: relative;
  top: -2px;
  left: 3px;
}
.savings-data-not-found {
  font-size: 24px;
  display: flex;
  padding: 58px 0px;
  justify-content: center;
}

.costimize-header-body {
  width: 100%;
  position: fixed;
  margin-left: 4.6rem;
  padding: 0 6.9rem 0 2.3rem;
  /* z-index: 11; */
  z-index: 1;
  background-color: var(--costimize_background_color);
}
.report-header-body {
  width: 100%;
  position: fixed;
  margin-left: 4.6rem;
  padding: 0 6.9rem 0 2.3rem;
  z-index: 1;
  background-color: var(--costimize_background_color);
}

.chart-space > .ant-card-body {
  margin: 0px !important;
  padding: 22px 0px 0px !important;
}

.costimize-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}

.costimize_header_logo {
  width: 145px;
  height: 29px;
  margin-top: 4px;
  background: #fff;
}

.costimize-sidebar > .ps-sidebar-container {
  background-color: var(--costimize_site_color) !important;
  padding-top: 16px;
  color: #fff;
}

.mobile-costimize-sidebar > .ps-sidebar-container {
  background-color: var(--costimize_site_color) !important;
  padding-top: 70px;
  color: #fff;
}

.costimize-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  /* color: var(--costimize_site_color) !important; */
  background-color: transparent !important;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: transparent !important;
  color: white !important;
  position: relative;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  color: white !important;
  background-color: transparent !important;
  position: relative;
}

/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 #141416;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px #141416;
}

.sidemenu-toggle {
  background: var(--costimize_site_color);
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 7px;
}

.header-title {
  font-weight: 600;
  color: var(--orange-color);
  font-size: 22px;
}

.header-logo-block {
  text-align: end;
}

.header-filter-block {
  text-align: end;
}

.header-filter-list {
  padding: 0px !important;
}

.header-filter-list li {
  display: inline;
  padding: 0;
  margin: 0 0 0 20px;
  cursor: pointer;
  font-weight: 500;
  font-size: 12.24px;
  color: var(--orange-color);
}

.header-filter-list li.active {
  /* border-bottom: 2px solid #433f3f; */
  border-bottom: 2px solid var(--orange-color);
}

.header-filter-btn {
  /* background: #ffffff; */
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
  border-radius: 7px;
  padding: 10px;
  color: #fff;
  /* color: var(--costimize_site_color) !important; */
}

/* .header-filter-btn-active {
  background: #e7e7e7 !important;
} */

.filter-dropdown-render {
  width: 200px;
  color: #fff !important;
  /* color: #848484 !important; */
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  /* background-color: #0F0F0F !important; */
  /* background-color: var(--costimize_lite_site_color); */
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px;
}

.filter-dropdown-scroll {
  height: 550px !important;
  overflow-y: scroll !important;
}

.filter-menu-dropdown-render {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  color: #fff !important;
  /* background-color: #fff; */
  width: 182px;
  border-radius: 11px;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 6px 16px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px -4px, rgba(0, 0, 0, 0.05) 0px 9px 28px;
}

.budget-table-top-icon {
  padding: 10px;
}

.plat-header-filter {
  width: 200px;
  z-index: 2 !important;
  position: fixed !important;
}

.plat-filter-menu-list {
  z-index: 2 !important;
  position: fixed;
}

.plat-header-filter ul {
  background-color: var(--costimize_lite_site_color) !important;
}

.plat-header-filter ul li:hover {
  background-color: var(--costimize_lite_site_color) !important;
  cursor: default !important;
}

.filter-header {
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: space-between;
}

.filter-dropdown-render > .ant-space {
  width: 100%;
}

.filter-header-right {
  font-size: 11px;
  font-weight: 400;
  padding-top: 1px;
}

.main-headtext {
  color: var(--orange-color) !important;
}

.filter-header-left {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0px;
}

.filter-sub-title {
  font-weight: 500;
  font-size: 11.24px;
  line-height: 14px;
  text-align: center;
}

.block-title {
  font-weight: 600;
  font-size: 17px;

  color: #ff6a2f;
}

.costimize-sidebar.ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  color: var(--orange-color) !important;
}

.text-justify {
  text-align: justify;
  color: white;
}

.block-title-20 {
  font-weight: 600;
  font-size: 20px;
  color: #ff6a2f;
}

.plat-menu-list {
  padding: 0px 0px 0px 8px;
  margin: 0rem;
  font-size: 12px;
}

.header-menu-filter-btn {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );

  /* background: #ffffff; */
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  color: #fff;
  /* color: #000; */
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  padding: 6px;
  display: flex;
  justify-content: space-between;
}

.filter-menu-dropdown-render .ant-dropdown-menu {
  /* position: absolute; */
  /* top: 35px; */
  width: 100%;
  height: 100px;
  overflow-y: scroll;
}

.menu-filter-search {
  /* position: absolute; */
  width: 100%;
  top: 0;
  /* background-color: #0F0F0F !important; */
  color: #fff !important;
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    ) !important;
}
.potential-savings-container {
  display: flex;
  align-items: center;
}

.savings-content {
  display: flex;
  align-items: center;
  font-size: 1em;
}

.value-section {
  display: flex;
  align-items: right;
}
.card-heading {
  color: white;
  font-size: 1.25em;
  margin-top: 1em;
  margin-left: 1em;
  margin-right: 1em;
}
/* card style */
.plat-card-1 {
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */

  height: 100%;

  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.card-title-1 {
  font-weight: 600;
  /* font-size: 13px; */
  font-size: 11px;
  /* line-height: 25px; */
  color: #fff;
  /* color: #848484; */
  text-align: center;
}

.card-value-1 {
  font-weight: 500;
  color: var(--orange-color) !important;
  /* font-size: 23.9141px; */
  font-size: 17px;
  /* line-height: 36px; */
  text-align: center;
}

.plat-card-1 .ant-card-body {
  padding: 12px;
}
.plat-card-p-0 .ant-card-body {
  padding: 0;
}
.card-bottom-content {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  padding: 0px;
  margin: 0px;
  color: #fff;
}

.card-1-bottom-value {
  font-weight: 400;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: #fff;
  /* color: #433f3f; */
  margin: 0;
}

.card-1-bottom-flex {
  display: flex;
  justify-content: space-evenly;
}

.plat-card-2 {
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
  /* border-radius: 11.1579px; */
  height: 100%;

  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.plat-card-modal {
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
}

.plat-card-modal .ant-modal {
  background-color: #141416 !important;
}

.plat-card-2-1 {
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%; */
  height: 100%;

  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.card-2-title {
  font-weight: 400;
  font-size: 13.9504px;
  line-height: 18px;
  color: #fff;
  /* color: #848484; */
  text-align: center;
}

.card-2-value {
  font-weight: 500;
  font-size: 18.9857px;
  line-height: 25px;
  /* color: #222222; */
  color: var(--orange-color);
  text-align: center;
  margin: 0rem;
}

.plat-card-2 .ant-card-body {
  padding: 20px;
}

.plat-card-2-1 .ant-card-body {
  padding: 30px;
}

.plat-card-3 {
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
  height: 100%; */
  height: 100%;

  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.plat-card-3 .ant-card-body {
  margin: 0px !important;
  padding: 0px !important;
  height: 100px !important;
}

.tagging-relative {
  position: relative;
}

.tagging-absolute {
  position: absolute;
  top: 0.5rem;
  right: 1rem;
}
.budget-search {
  display: flex;
  position: absolute;
  right: 0;
  z-index: 1;
  top: -5rem;
}
.costimize-collapse {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 11.1579px;
}

.constimize-event-list {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  color: #433f3f;
  padding: 6px;
}

.constimize-event-title {
  font-weight: 600;
  font-size: 17px;
  line-height: 100%;
  color: #433f3f;
}

.spend-chart-title {
  font-weight: 500;
  font-size: 15.24px;
  line-height: 100%;
  margin-bottom: 0px !important;
  margin-top: 0.7rem;
  color: #fff;
  /* text-align: left !important; */
  text-align: center;
  margin-left: 4%;
}

.spendbudget-chart-title {
  font-weight: 500;
  font-size: 15.24px;
  line-height: 100%;
  margin-bottom: 0px !important;
  margin-top: 2.7rem;
  color: #fff;
  /* text-align: left !important; */
  text-align: center;
  margin-left: 4%;
}

.chart-filter {
  border-radius: 4px;
  border: 0.5px solid #848484;
  background: #9a9a9a;
  color: #fff;
  font-size: 12px;
}

.spend-day-chart-title {
  font-weight: 400;
  font-size: 16.8249px;
  line-height: 25px;
  margin-bottom: 0px !important;
  margin-top: 0.3rem;
  text-align: left !important;
  /* color: #848484; */
  color: #fff;
}

.costimize-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  color: var(--orange-color) !important;
}

.apexcharts-tooltip {
  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  /* background-color: #848484 !important; */
  /* background: var(--11,
      linear-gradient(180deg,
        rgba(255, 255, 255, 0.08) 0%,
        rgba(255, 255, 255, 0) 468.03%)) !important; */
  background: #0f0f0f !important;
  color: #848484 !important;
  backdrop-filter: blur(5px) !important;
  /* color: var(--orange-color) !important; */
}
.sidebar-footer {
  position: fixed;
  bottom: -20px;
  width: 100%;
  padding: 15px;
  /* background-color: inherit; */
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.powered-by-image1 {
  max-height: 36px;
  width: auto;
}
.side-icon-hover:hover {
  color: var(--orange-color) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  border-radius: 5px;

  padding: 2px;
  /* Add padding to give it some space */
}

.spend-day-chart-content {
  font-weight: 400;
  font-size: 13px;
  line-height: 20px;
  text-align: center;
  padding: 0px;
  margin-top: 6px;
  color: #fff;
}

.quick-glance-icon {
  margin: -2px 6px 0px;
  font-size: 20px;
}

.pa-quick-glance-icon {
  margin: 14px;
  font-size: 30px;
}

/* .chart-filter {
  position: absolute;
  top: -7px;
  right: 4px;
  font-size: 17px;
  color: #fff;
} */

.chart-filter2 {
  position: absolute;
  top: -9px;
  right: 12px;
  font-size: 17px;
  color: #fff;
}

.ant-dropdown .ant-dropdown-menu {
  /* background-color: transparent !important; */
  background-color: var(--costimize_site_color);
  /* color: rgba(255, 255, 255, 0.4) !important; */
  color: #fff !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  /* color: rgba(255, 255, 255, 0.4) !important; */
  color: #fff !important;
}

.ant-dropdown .ant-table-filter-dropdown {
  background-color: #000;
  color: #fff !important;
}

.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  color: #fff !important;
  /* background-color: #000 !important; */
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
  color: #fff !important;
  background-color: #000 !important;
}

.ant-dropdown-menu-submenu
  .ant-dropdown-menu
  .ant-dropdown-menu-submenu-title-selected {
  background-color: #000 !important;
  color: #fff !important;
}

.chart-drop.ant-dropdown .ant-dropdown-menu {
  background: #008000 !important;
}

.plat-padding {
  padding: 7px;
}

.card-1-title-2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 100%;
  text-align: center;
  margin: 0rem;
  color: #fff;
}

.costimize-center-icon {
  width: 24px;
  height: 24px;
  margin: 1.5rem;
  color: var(--orange-color);
}

.card-1-value-2 {
  font-weight: 600;
  font-size: 24px;
  line-height: 100%;
  text-align: center;
  color: var(--orange-color);
}

/* asset */

.spend-search {
  display: flex;
  position: absolute;
  /* justify-content: right; */
  right: 0;
  z-index: 1;
  top: 14px;
}
.budget-cost-value {
  color: white;
}
.spend-asset-body {
  /* position: relative; */
  display: block;
}

.tagging-p-title {
  font-size: 20px;
  text-align: left;
}

.tagging-p-value {
  margin: 0;
  text-align: left;
}

.tagging-p-icon {
  cursor: pointer;
  margin: 2px;
}

/* tab */
.costimize-table-tab > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: var(--costimize_background_color) !important;
  color: #fff !important;
}

.assetspend-costimize-table-tab
  .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  background: var(--costimize_background_color) !important;
  color: #fff !important;
}

span.ant-table-column-sorter-inner {
  /* color: #b0a8a8 !important; */
  color: #ff6a2f !important;
}

span.anticon.anticon-caret-down.ant-table-column-sorter-down.active {
  color: #fff !important;
}

span.anticon.anticon-caret-up.ant-table-column-sorter-up.active {
  color: #fff;
}

.costimize-bootstrap-table thead th label.filter-label {
  display: block !important;
}

.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background-color: var(--costimize_background_color) !important;
  color: #848484 !important;
  /* color:#FFF; */
  font-weight: 600 !important;
  font-size: 17px !important;
  line-height: 100% !important;
  padding: 13px 15px !important;
  border: none !important;
}

.ant-input {
  border-color: grey !important;
}

.ant-input:hover {
  border-color: blue !important;
}

.ant-input:hover {
  border-color: blue !important;
}

.ant-input:focus {
  border-color: blue !important;
}

.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #fff !important;
  background: transparent !important;
  /* color: var(--costimize_site_color) !important; */
}

.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background: var(--costimize_site_color) !important;
}

.costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  /* color: var(--costimize_site_color) !important; */
  color: #fff !important;
}

/* assets tab */
.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list {
  background: var(--costimize_background_color) !important;
}

.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab {
  background-color: var(--costimize_background_color) !important;
  color: #848484 !important;
  font-weight: 500;
  font-size: 17px;
  line-height: 100%;
  padding: 13px 15px !important;
  border: none !important;
}

.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active
  > .ant-tabs-tab-btn {
  color: #fff !important;
  background: transparent !important;
}

.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-ink-bar {
  background: var(--costimize_site_color) !important;
}

.assetspend-costimize-table-tab
  > .ant-tabs-nav
  > .ant-tabs-nav-wrap
  > .ant-tabs-nav-list
  > .ant-tabs-tab.ant-tabs-tab-active {
  /* color: var(--costimize_site_color) !important; */
  color: #fff !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #fff !important;
  background: transparent !important;
  /* font-weight: 500; */
}

.assent-spend-head {
  /* background: var(--11, linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 468.03%)); */
  /* background:#000 !important; */
  /* background: var(--costimize_site_color); */
  padding: 18px 0px;
  color: #fff !important;
  border-top-left-radius: 11.1579px;
  border-top-right-radius: 11.1579px;
  position: sticky;
  border-radius: 8px 8px 0px 0px !important;
  background: #2e2e2e;

  /* background-color: rgba(255, 255, 255, 0.06) !important; */
}

.plat-asset-spend-body-vs {
  /* margin-top: 26px; */
  /* background-color: "rgba(255, 255, 255, 0.06)" !important; */
  color: #fff;
  background: #202020;
  /* background-color: rgba(255, 255, 255, 0.06) !important; */
}

.asset-spend-title-vs {
  font-weight: 600;
  font-size: 12.24px;
  line-height: 18px;
  padding-left: 16px;
  display: flex;
  align-items: center;
}

.all-environment-icon {
  position: absolute;
  top: 21px;
  left: 7px;
  height: 100%;
  display: flex;
  align-items: center;
}

.environment-list-icon {
  position: absolute;
  top: 24px;
  left: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}

.cloud-list-icon {
  position: absolute;
  top: 24px;
  left: 2px;
  height: 100%;
  display: flex;
  align-items: center;
}

.application-list-icon {
  position: absolute;
  top: 22px;
  left: 40px;
  height: 100%;
  display: flex;
  align-items: center;
}

.dropdown-menu.show {
  display: block;
  background: #0f0f0f !important;
}

.application-list-icon-new {
  position: absolute;
  top: 22px;
  left: 62px;
  height: 100%;
  display: flex;
  align-items: center;
}

.plat-chart-center {
  display: flex;
  justify-content: center !important;
}

.asset-spend-values-vs {
  font-weight: 400;
  font-size: 12.24px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.asset-spend-border-bottom-vs {
  border-bottom: 0.37px solid #848484;
  width: 100% !important;
}

.cd-accordion--animated-vs {
  border-radius: 12px;
  margin: 0px;
  padding: 0px;
  /* background: #fff !important;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
}

/* .costimize-asset-spend-scroll {
  position: sticky;
  top: 143px;
  z-index: 1;
} */

.apexcharts-legend-series {
  text-align: left !important;
}

.assent-spend-footer {
  height: 1rem;
}

.plat-icon-bg {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  color: var(--orange-color);
  border: var(--orange-color) 1px solid;
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
  border-radius: 8px;
  padding: 1px 9px;
  width: 40px;
  height: 27px;
  margin: 0 8px;
}

.factory-icon {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
}

.factory-icon {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  color: var(--orange-color);
  border: var(--orange-color) 1px solid;
}

.popup-chart-cart {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );

  /* background: #ffffff; */
  box-shadow: 0px 3.78126px 3.78126px rgba(217, 217, 217, 0.6);
  border-radius: 10.5477px;
}

.details-recommendation-title {
  text-align: center;
  font-weight: 500;
  font-size: 13.0495px;
  line-height: 20px;
}

.details-recommendation-list {
  text-align: left;
  display: flex;
  justify-content: space-between;
}

ul.details-rec-right {
  display: flex;
}

.details-rec-right li {
  padding: 7px;
}

.recom-table {
  width: 100%;
  border-collapse: collapse;
}

.recom-table td,
th {
  border: none;
}

/* table */
.costimize-bootstrap-table thead th {
  background-color: #2e2e2e !important;
  color: var(--orange-color) !important;
  /* color: #fff !important; */
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  /* text-align: center !important; */
  z-index: 0 !important;
}

.costimize-savings-table-footer {
  background: var(--costimize_site_color) !important;
  /* color: #fff !important; */
  color: var(--orange-color);
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}

.costimize-savings-table-footer
  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  background: var(--costimize_site_color) !important;
  color: var(--orange-color);
}

.costimize-savings-table-footer
  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: var(--costimize_site_color) !important;
}

.costimize-bootstrap-table thead {
  color: "orange" !important;
}

.costimize-bootstrap-table thead th .order > .dropup > .caret {
  margin: 10px 0;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.costimize-bootstrap-table thead th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}

.costimize-bootstrap-table
  thead
  th
  > .react-bootstrap-table-sort-order.dropup
  > .caret {
  color: #848484 !important;
  margin: 10px 6.5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: none !important;
}

.costimize-bootstrap-table
  thead
  th
  > .react-bootstrap-table-sort-order
  > .caret {
  margin: 10px 6.5px;
  color: #848484 !important;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}

.costimize-bootstrap-table thead td {
  background-color: #2e2e2e !important;
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  z-index: 0 !important;
}

/* .filter.number-filter {
    display: flex;
}
.filter.date-filter {
    display: flex;
}
select#date-filter-comparator-date {
    width: 38px !important;
}
select#number-filter-comparator-potential_savings {
    width: 38px !important;
} */

.custimize-drop-down-list .ant-dropdown-menu {
  background-color: var(--costimize_site_color) !important;
  color: #fff !important;
  width: 162px !important;
}

.custimize-drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}

.custimize-drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}

.slm-drop-down-list .ant-dropdown-menu {
  background-color: #1c344c !important;
  color: #fff !important;
  width: 162px !important;
}

.slm-drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}

.slm-drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}

.costimize-bootstrap-table tbody td {
  /* background-color: #fff !important; */
  /* color: #000; */
  color: #fff;
  background-color: #202020;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  border-bottom: 0.37px solid #848484 !important;
  text-align: left;
  white-space: nowrap;
  border: none;
  z-index: 0 !important;
}

.costimize-bootstrap-table.ant-table-wrapper .ant-table-cell-fix-left,
:where(.css-dev-only-do-not-override-j0nf2s).ant-table-wrapper
  .ant-table-cell-fix-right {
  position: sticky !important;
  z-index: 2;
  background: #202020;
}

.custom-no-hover:hover {
  background-color: transparent !important;
}

.costimize-bootstrap-table tfoot th {
  background-color: var(--costimize_site_color) !important;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  text-align: left !important;
  white-space: nowrap;
  border: none !important;
}

.custom-sort-up .react-bootstrap-table-sort-order,
.custom-sort-down .react-bootstrap-table-sort-order {
  /* color: #fff !important; */
  color: #848484 !important;
}

.costimize-bootstrap-table tfoot > tr:first-child > *:first-child {
  border-end-start-radius: 8px !important;
}

.costimize-bootstrap-table tfoot > tr:first-child > *:last-child {
  border-end-end-radius: 8px !important;
}

.react-bs-table-sizePerPage-dropdown {
  display: none;
}

.costimize-table-width
  > .react-bootstrap-table-pagination
  > .react-bootstrap-table-pagination-list
  > .react-bootstrap-table-page-btns-ul
  > .active
  > .page-link {
  background-color: var(--costimize_site_color) !important;
}

.table-bordered {
  border: none;
}

.number_right {
  text-align: right !important;
  float: right !important;
}

.table-bordered td,
.table-bordered th {
  border-top: none;
  border-bottom: none;
}

.costimize-bootstrap-table thead > tr:first-child > *:first-child {
  border-start-start-radius: 8px !important;
}

.costimize-bootstrap-table thead > tr:first-child > *:last-child {
  border-start-end-radius: 8px !important;
}

/* .costimize-bootstrap-table .ant-pagination {
  position: sticky;
  bottom: 0;
} */
.costimize-table-width {
  overflow-x: auto;
  width: 90vw;
}

.cancel-btn {
  border-radius: 4px;
  border: 1px solid #787878;
  color: #fff;
  background-color: transparent !important;
}

.submit-btn {
  /* background: #FF6A2F; */
  color: #fff;
  float: right;
  background: var(
    --3,
    linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)
  );
}

.table-add-button {
  border-radius: 6px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
  border: none;
  color: #fff;
  /* background: #ffffff; */
  /* background: #0f0f0f; */
  /* box-shadow: 0px 4px 4px rgba(43, 38, 38, 0.25);
  border-radius: 4px; */
  margin: 6px;
  padding: 7px 24px;
  float: right;
}

.drawer-spend-invoice {
  font-weight: 600;
  font-size: 19px;
}

/* tagging */
.tagging-pop-ul-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  color: #ff6a2f;
  /* width: 250px; */
}

.modal-tagging {
  background-color: #000 !important;
  color: #fff !important;
}

.ant-modal .ant-modal-content {
  background-color: #000 !important;
}

.ant-modal .ant-modal-title {
  background-color: #000;
  color: #fff;
}

.tagging-popup-body {
  background-color: #1f1f1f !important;
  color: #fff !important;
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
  border-radius: 11.1579px;
  padding: 21px 0px;
  font-weight: 500;
  font-size: 10px;
}
.csp {
  cursor: pointer;
}
.tagging-popup-sig-arrow {
  font-size: 23px;
}

.tagging-popup-service {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tagging-category-popup {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.tagging-popup-button {
  color: #000 !important;
}

.taggin-pop-btu {
  background: #fff !important;
  margin: 0 2px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
}

.popup-delete-button {
  color: red !important;
}

.tagging-update-title {
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  text-align: left;
}

span.ant-dropdown-trigger.ant-table-filter-trigger.ant-dropdown-open {
  color: #fff;
}

span.ant-dropdown-trigger.ant-table-filter-trigger {
  color: #fff;
}

.spend-day-back-btu {
  font-size: 21px;
  color: #fff;
  border: 1px solid #fff;
  padding: 2px;
  margin-right: 7px;
  margin-top: 5px;
  cursor: pointer;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.costdemaze_switch {
  background: var(--costimize_site_color) !important;
}

.red-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: red;
  margin-left: 7px;
}

.yellow-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #fcaf04;
  margin-left: 7px;
}

.ant-drawer-title {
  font-size: 22px !important;
  font-weight: 600 !important;
}

.green-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #008000;
  margin-left: 7px;
}

.yellow-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #c30;
  margin-left: 7px;
}

.lite-red-round {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #ff8383;
  margin-left: 7px;
}

.instance-title {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
}
.ant-select .ant-select-clear {
  top: 40%;
  color: #fcaf04 !important;
  font-size: 14px !important;
  background: #000 !important;
}

/* media query */
@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 0 3.9rem 0 3.5rem;
    background-color: var(--costimize_background_color);
  }

  .header-filter-block {
    text-align: center;
  }

  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 0px;
  }

  .costimize-body-screen {
    margin-top: 7.5rem;
    margin-left: 0.7rem;
    padding: 0px 3.3rem;
    width: 100%;
  }

  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 0 3.9rem 0 3.5rem;
    background-color: var(--costimize_background_color);
  }

  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 0px;
  }

  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 3.3rem;
    width: 100%;
  }

  .header-filter-block {
    text-align: center;
  }

  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 1rem 1.5rem 0;
    background-color: var(--costimize_background_color);
  }

  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 53px;
  }

  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 1.3rem;
    width: 100%;
  }

  .header-filter-block {
    text-align: center;
  }

  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }
}

@media (max-width: 320px) {
  .costimize-header-body {
    width: 100%;
    position: fixed;
    margin-left: 0rem;
    padding: 1rem 1.5rem 0;
    background-color: var(--costimize_background_color);
  }

  .sidebar-mobile-main {
    z-index: 3;
    position: fixed;
    padding: 12px;
    margin-top: 51px;
  }

  .costimize-body-screen {
    margin-top: 10.5rem;
    margin-left: 0.7rem;
    padding: 0px 1.3rem;
    width: 100%;
  }

  .header-title {
    font-weight: 600;
    font-size: 18px;
    margin-top: 5px;
    /* text-align: center; */
  }

  .header-logo-block {
    text-align: center;
  }

  .plat-card-3 .ant-card-body {
    margin: 0px !important;
    padding: 0px !important;
    height: 172px !important;
  }

  /* .header-filter-block {
        text-align: center;
    } */
  /* .header-filter-list li {
        display: block;
        padding: 0;
        margin: 0 0 0 20px;
        cursor: pointer;
        font-weight: 500;
        font-size: 12.24px;
        color: var(--costimize_site_color);
    } */
}

/* .ant-form-vertical .ant-form-item-label > label {
  background-color: #0f0f0f;
  color: #fff;
}

.ant-drawer .ant-drawer-content {
  background-color: #0f0f0f;
  color: #fff;
}

.ant-input {
  background-color: #0f0f0f;
  /* border-color: #a19e9e; */
/* color: #fff;
} */

/* .react-datepicker__input-container input {
  background-color: #0f0f0f;
  color: #fff;
}

.ant-drawer .ant-drawer-title {
  color: #fff;
}

.anticon {
  color: #fff;
} */

.ant-pagination {
  color: #e7e7e7;
  background-color: #0f0f0f;
}

.ant-pagination .ant-pagination-item-active {
  font-weight: 600;
  /* background-color: #ffffff; */
  border-color: #848484 !important;
  background-color: #0f0f0f !important;
  color: #fff !important;
}

.ant-select-selector {
  border-color: #848484 !important;
  background-color: #0f0f0f !important;
  color: #fff !important;
}

.ant-pagination
  .ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis,
.ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff !important;
}

.ant-empty-normal .ant-empty-description {
  color: var(--orange-color);
}

.ant-modal-close-x {
  color: white;
  /* Change the color to your desired color */
}

.costmodal .ant-modal-content {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  min-width: 37rem;
  margin-top: 15px;
  margin-left: -5rem;
}

.costmodal .ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  color: #ffff;
  /* border-bottom: 3px solid #ff6a2f; */
  /* border-color: #ff6a2f; */
  margin: 0px 5px 0px 4px;
  /* text-align: center; */
  /* font-size: 1.25rem; */
  /* width: 10rem; */
}

.costmodal .ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  color: #ffff;
  /* border-bottom: 3px solid #ff6a2f; */
  /* border-color: #ff6a2f; */
  margin: 0px 5px 0px 4px;
  /* text-align: center; */
  /* font-size: 1.25rem; */
  /* width: 10rem; */
}

.slm-table .footer {
  text-align: right;
  color: #fff !important;
  background-color: transparent !important;
}

.slm-table .ant-table-wrapper .ant-table-footer {
  color: #fff !important;
  background-color: transparent !important;
  display: flex;
  justify-content: right;
}

.ant-table-wrapper .ant-table-footer {
  color: var(--orange-color) !important;
  background-color: transparent !important;
  display: flex;
  justify-content: right;
}

.table-forecast {
  width: 100% !important;
}

.table-forecast .ant-modal .ant-modal-body {
  padding-top: 10px !important;
}

.costmodal .ant-modal-title {
  background: #202020;
  color: #fff;
  font-family: Poppins;
  font-size: 1.437rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* border-bottom: 2px solid #787878; */
  padding-bottom: 1rem;
}

.anticon svg {
  color: #fff;
  /* padding-top: 5px; */
}

.costmodal .ant-modal .ant-modal-header {
  background: transparent;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
}

.costmodal .ant-form-vertical .ant-form-item-label > label {
  background-color: transparent;
  color: #fff;
}

.costmodal .ant-input {
  background-color: #1f1f1f;
  border: 1px solid white;
  color: #fff;
}

.costmodal .ant-select-selector {
  border-color: #848484 !important;
  background-color: transparent !important;
  color: #fff !important;
}

.costmodal .ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.2s, box-shadow 0.2s;
}

.costmodal .react-datepicker__input-container input {
  background-color: transparent;
  color: #fff;
}

.costmodal .ant-picker .ant-picker-input > input {
  color: #fff;
}

.costmodal .form-control {
  min-height: auto;
  padding-top: 4px;
  padding-bottom: 3.28px;
  transition: all 0.1s linear;
  background: transparent;
}

.costmodal
  .ant-input-number-status-error:not(.ant-input-number-disabled):not(
    .ant-input-number-borderless
  ).ant-input-number {
  border-color: #ff4d4f;
  background: transparent;
}

.costmodal
  .ant-input-number-affix-wrapper-status-error:not(
    .ant-input-number-affix-wrapper-disabled
  ):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper {
  border-color: #ff4d4f;
  background: transparent;
}

.costmodal .ant-input-number-affix-wrapper {
  position: relative;
  display: inline-flex;
  width: 90px;
  min-width: 0;
  padding: 0;
  color: white;
  font-size: 14px;
  line-height: 1.5714285714285714;
  background-color: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  padding-inline-start: 11px;
}

.costmodal .ant-input-affix-wrapper {
  background-color: transparent;
  color: #fff;
}

.costmodal .ant-input-number {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-block;
  width: 90px;
  min-width: 0;
  background-color: transparent;
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
  border-radius: 6px;
  transition: all 0.2s;
  border: 1px solid #d9d9d9;
}

.costmodal .ant-input-number .ant-input-number-input {
  width: 100%;
  height: 30px;
  padding: 0 11px;
  text-align: start;
  background-color: transparent;
  border: 0;
  border-radius: 6px;
  outline: 0;
  transition: all 0.2s linear;
  appearance: textfield;
  color: white;
  font-size: inherit;
  vertical-align: top;
}

.costmodal .ant-checkbox + span {
  padding-inline-start: 8px;
  padding-inline-end: 8px;
  color: white;
}

.note-content {
  color: white;
}

.invoicedrawer-spend {
  color: white;
  font-size: 1.5rem;
}

.slm.ant-table-wrapper .ant-table-thead > tr > td {
  color: #ff6a2f;
  text-align: center;
  border: none;
}

.slm.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}
.slm.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  /* border-top: 0.474px solid #84848484; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.178) !important;
  text-align: center;
  color: white;
}

.slm .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.slm .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}

.slm .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.slm .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
  color: white;
}

.slm .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.slm .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 2em;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.slm.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 10rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}

.slm.ant-table-wrapper .ant-table {
  background-color: #202020;
  margin-top: 0.8rem;
  color: #ffffff;
}

.card-2-font {
  font-size: 16.8249px !important;
}
.tableclass {
  width: 50%;
}
.my-select {
  color: white;
}
.ant-select-dropdown {
  box-sizing: border-box;
  margin: 0;
  padding: 4px;

  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: absolute;
  top: -9999px;
  z-index: 1050;
  overflow: hidden;
  font-variant: initial;
  background-color: black !important;
  border-radius: 8px;
  outline: none;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}
.ant-select-dropdown .ant-select-item {
  position: relative;
  display: block;
  min-height: 32px;
  padding: 5px 12px;
  color: white;
  font-size: 14px;
  line-height: 1.5714285714285714;
  box-sizing: border-box;
  cursor: pointer;
  border-radius: 4px;
}

/* //Weekly Report CSS */
.horizontal-bar {
  width: 100%;
  height: 1rem;
  background-color: #ff6a2f;
  /* padding: 0rem; */
  margin: 0rem;
}
.headerrow {
  display: flex;
  justify-content: center;
  /* margin-top: 20%; */
}
.texts-center {
  display: block;
  text-align: center;
}
.companyname {
  color: #ff6a2f;
}
.headingcenter {
  color: #000;
}
.subheadingrow {
  display: flex;

  /* flex-flow: row wrap; */
  justify-content: center;
}
.subheadingtab {
  font-size: 2.75rem;
}
.headingtab {
  font-size: 5.5rem;
}
.white-bg {
  background: white;
  width: 100%;
  height: 100%;
  /* position: fixed; */
}
.page1 {
  width: 100%;
  height: 90vh;
  /* position: fixed; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.scroll {
  margin: 3rem;
}
.chartheading {
  display: flex;
  justify-content: left;
  color: #ff6a2f;
  font-family: Poppins;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 1.25em;
}
.headerheading {
  display: flex;
  justify-content: center;
  color: #ff6a2f;
  font-family: Poppins;

  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-size: 1.1em;
}
.report-cards {
  border: 1px solid #cfcfcf;
  background: #eee;
  border-radius: 0.625rem;
  backdrop-filter: blur(1.9108495712280273px);
  margin-bottom: 0.5em;
}
.boldheading {
  font-size: 1.25rem;
  margin-top: 0.5em;
  color: #3b3b3b;
  font-weight: 700;
  /* text-align: center; */
}

.dateheading {
  margin-top: -1rem;
  font-size: 1rem;
  border: 1px solid black;
  display: inline-block;
  padding: 0.5rem;
  font-weight: bold;
  /* font-size: 1rem; */
}
.summary {
  text-align: center;
  color: #3b3b3b;
  font-weight: 600;
}
.ruler {
  background-color: #ff6a2f;
  margin-top: 0.5em;
}
/* .chartsrow {
  margin-top: 0.5em;
} */
.tableheading {
  color: #ff6a2f;
  font-weight: 600;
  font-size: 1.15em;
}
.subtableheading {
  color: hsl(17, 100%, 59%);
  font-weight: 550;
  font-size: 1.25em;
}
.footerrow {
  margin-left: 4rem;
  margin-right: 4rem;
}
.align-right {
  text-align: left;
}
.ruler.footerruler {
  margin-left: 4rem;
  margin-right: 4rem;
}
.atagcolor {
  color: #000;
}
.linktag {
  color: #ff6a2f;
  font-weight: 500;
  margin-left: 3rem;
}
.weeklytable.ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #cfcfcf;
  border-bottom: 1px solid #cfcfcf;
  transition: background 0.2s ease;
}
.weeklytable.ant-table-wrapper .ant-table-tbody > tr > td {
  transition: background 0.2s, border-color 0.2s;
  border-bottom: 1px solid #f0f0f0;
  padding: 5px 9px 9px 10px;
  font-size: 0.85em;
}
.weeklytable.ant-table-wrapper table {
  width: 100%;
  text-align: start;
  border-radius: 8px 8px 0 0;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #cfcfcf;
  margin-top: 0.4em;
  margin-bottom: 0.5em;
}
.weeklytable .ant-table-cell {
  border: 1px solid #ddd; /* Add a border to the table cells */
}
.weeklytable.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: center;
  background: #cfcfcf;
  /* border-bottom: 1px solid #f0f0f0; */
  transition: background 0.2s ease;
  font-size: 0.7em;
  margin-bottom: 0.25em;
}
.subtext {
  display: flex;
  justify-content: center;
  font-weight: 550;
  font-size: 11.5px;
  margin-bottom: 0.4em;
}
span.subscript {
  font-size: 8.5px;
}
.total-row {
  font-weight: bold;
}

.number-title {
  display: inline-block;
  color: white;
}
.daterangefilter.ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  color: black;
}
.daterangefilter {
  background-color: #fff;
  margin-top: 0.5em;
}
.highlight-row {
  background-color: #ffff00e3;
  border: none;
}
.table-cell {
  /* white-space: wrap;
  overflow: hidden; */
  /* text-overflow: ellipsis; */
  font-stretch: condensed;
}
ul {
  padding: 0;
}

.filter-search-input input {
  background: white;
}

.ant-modal.table-forecast .ant-modal-close {
  top: 38px;
  right: 35px;
}

.antd-input-theme {
  border: 1px solid var(--site_color);
  width: 4rem;
  color: white;
}

.costmodal .ant-input.tracking-input:focus,
.costmodal .ant-input.tracking-input:hover,
.costmodal .ant-input.tracking-input {
  background-color: transparent;
  border: 1px solid #848484 !important;
  color: #fff;
  width: 90%;
}

.ant-dropdown .ant-table-filter-dropdown .ant-dropdown-menu {
  background: #1e1e1e;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
}

.ant-btn.ant-btn-sm {
  background-color: #ff6a2f;
  color: white;
}

.ant-dropdown .ant-table-filter-dropdown .ant-table-filter-dropdown-btns {
  background: #1e1e1e;
}

.dropdownbuttonwidth {
  width: 30rem;
}

.yes-btn dropdownbuttonwidth{
  color: #fff !important;
    background-color: #494949;
    border: none !important;
    border-radius: 7px !important;
    float: left !important;
    cursor: pointer;
}

.ant-form-item-control-input-content {
  display: flex;
  justify-content: space-between;
}

.user-create .ant-steps-item-content {
  padding-right: 15px;
}

.devops-form-steps.user-create .ant-steps-item.ant-steps-item-active::before {
  width: 83%;
}

.switch-width {
  background-color: #ff6a2f;
  color: white;
  width: 2%;
}

.ant-form-item .ant-form-item-explain-error {
  color: #ff4d4f;
  display: flex;
}
.para {
  margin-bottom: -1.5em;
}
.asset-icon {
  font-size: 20px;
  padding: 2px;
}
.assets-table.ant-table-wrapper .ant-table {
  background: #202020;
  color: #ffffff;
  font-size: 0.75rem;
}
.assets-table.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row
  > .ant-table-cell-row-hover {
  background: #202020 !important;
  color: #ffffff !important;
  font-size: 0.75rem;
}
.assets-table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 3rem;
  border: none;
  text-align: center !important;
  font-size: 0.97rem !important;
}
.assets-table.ant-table-wrapper .ant-table-row-expand-icon {
  background: #202020 !important;
  color: #ffffff !important;
  margin-top: 0px !important;
}
.assets-table.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 1rem 1rem !important;
}
.asset_table_graph {
  height: 3rem;
  display: flex;
  align-items: center;
}
.assets-table.ant-table-wrapper .ant-table-pagination.ant-pagination {
  display: none;
}
.assets-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.assets-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.assets-tabs .ant-tabs-tab-btn {
  color: #ff6a2f !important;
  padding: 1px 25px 5px 25px !important;
  font-size: 1.19425rem !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 1.83119rem !important;
}
.asset-modal-date {
  font-weight: 400;
  font-size: 14px;
  color: #fff;
  display: flex;
  justify-content: right;
}
/* .assets-tabs .ant-tabs .ant-tabs-tab {
padding: 1px 0px !important;
} */
.ant-spin .ant-spin-dot-item {
  color: #ff6a2f !important;
}
