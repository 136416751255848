.custom-select-styles * {
  font-size: 13px;
}
.custom-select-styles {
  height: 100%;
}

.year-picker-cs .ant-picker-input input {
  font-size: 12.5px;
  font-weight: 500;
  padding: 4px 0px;
}

.year-picker-cs .ant-picker-focused.ant-picker {
  box-shadow: none;
}
.year-picker-cs input::placeholder {
  color: white !important;
}
.year-picker-cs .ant-picker {
  background: #212121;
  border: 1px solid #343434;
  color: white;
}

.year-picker-cs .ant-picker-suffix {
  position: relative;
  font-size: 17px;
  top: -3px;
}

.year-picker-cs .ant-picker .ant-picker-input > input,
.year-picker-cs .ant-picker .ant-picker-input > input:focus,
.year-picker-cs .ant-picker .ant-picker-input > input::placeholder {
  color: white;
}
:where(.css-dev-only-do-not-override-xu9wm8).ant-picker-dropdown
  .ant-picker-cell-disabled {
  opacity: 0.3;
  cursor: no-drop;
  pointer-events: all;
}
.year-picker-qg-top-right {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
}

/* .year-picker-cs .ant-picker-clear {
  position: absolute;
  background-color: transparent;
  top: 13px;
  transition: 0.3s;
  font-size: 17px;
  height: 20px;
  width: 20px;
} */

.vertical-button {
  position: fixed !important;
  height: 6rem;
  z-index: 99;
  display: inline-block;
  /* padding: 0.5rem 0.5rem; */
  padding: 2px;
  text-align: center;
  color: #ffffff;
  background-color: #3b3b3bd1;
  border-radius: 0 8px 8px 0;
  border: none;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  writing-mode: vertical-rl; /* Vertical writing mode */
}

.vertical-button:hover {
  background-color: #3b3b3b;
  color: #fff !important;
  border-color: none !important;
}
.vertical-button-zero {
  position: fixed !important;
  height: 6rem;
  font-size: 14px;
  display: inline-block;
  padding: 0.5rem 0.5rem;
  text-align: center;
  color: #ff6a2f;
  background-color: #fff;
  border-radius: 0 8px 8px 0;
  border: none;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
  writing-mode: vertical-rl; /* Vertical writing mode */
}
.vertical-button-zero:hover {
  background-color: #fff;
  color: #ff6a2f !important;
  border-color: none !important;
}
/* .ant-btn-default:hover{
  color:#fff !important ;
  border-color: #ff6a2f !important;
} */
.rotated {
  transform: rotate(180deg);
}
.filter-button {
  display: flex;
  justify-content: end;
  margin-top: 15rem !important;
  z-index: 10;
}
.filter-icon {
  transform: rotate(90deg);
  font-size: 16px;
  padding-top: 4px;
  color: #fff;
}
.filter-icon-zero {
  color: #ff6a2f !important;
  transform: rotate(90deg);
  font-size: 19px;
  padding-top: 4px;
}
.filter-badge {
  margin-top: -4px !important;
  margin-right: -3px;
  z-index: 1;
  position: fixed;
}
.ant-badge .ant-scroll-number .ant-scroll-number-only {
  transform: rotate(-90deg);
}
