.spendCardTitle {
  color: white;
  font-size: 11px;
  position: relative;
  z-index: 15;
}

.spendCardValue {
  color: #ff6a2f;
  font-size: 14px;
  margin: 0;
}

.spendCardBottomValue {
  color: white;
  font-size: 11px;
}

.costdmaze-info-table table {
  background: #212121;
  color: white;
  font-size: 12px;
}
.costdmaze-info-table.inner table td {
  background: #2e2e2e !important;
}
.costdmaze-info-table table td {
  background: #212121 !important;
}

.costdmaze-info-table td:last-child {
  border: 0;
}
.costdmaze-info-table .ant-spin-dot-item {
  background-color: #ff6a2f;
}
.costdmaze-info-table table tbody > tr:last-child > td {
  border: 0 !important;
}

.costdmaze-info-table .ant-table-wrapper .ant-table-footer {
  background: #212121f7 !important;
  border-radius: 0;
}

.costdmaze-info-table .ant-pagination-item-active {
  font-weight: 600;
  border-color: #848484 !important;
  background-color: #212121 !important;
  color: #ffffff !important;
  font-size: 12px;
}

.costdmaze-info-table .ant-pagination {
  background: #5c5c5c !important;
  padding: 11px 0;
  margin: 0 !important;
  border-bottom-right-radius: 10px;
  color: black;
  border-bottom-left-radius: 10px;
}
.costdmaze-info-table.inner .ant-table-wrapper .ant-table-thead > tr > th,
.costdmaze-info-table.inner .ant-table-wrapper .ant-table-thead > tr > td {
  background: #ff6a2fcf !important;
  opacity: 0.9;
}
.costdmaze-info-table .ant-table-wrapper .ant-table-thead > tr > th,
.costdmaze-info-table .ant-table-wrapper .ant-table-thead > tr > td {
  background: #ff6a2fcf !important;
  color: white;
  border: 0;
}

.costdmaze-info-table table .ant-table-cell-row-hover {
  background: none !important;
}
