.login-bg {
  /* background: #161616; */

  position: relative;
  z-index: 0;
}
.login-container {
}

.login-bg-img {
  background: url("../../assets/images/landing/landing-circle-bg.svg") no-repeat;
  background-repeat: no-repeat;
}
.plat-signgroup-right-body {
  /* padding: 0px 68px; */
  padding: 0px 20px;
}
.phone-no{
  padding-right: 5.5rem !important;
}
.emailname{
  padding-right: 3.5rem !important;
}
.lastnamecss{
  padding-right: 4.1rem !important;
}
.firstnamecss{
  padding-right: 4.1rem !important;
}
.pswdcss{
  padding-right: 2.5rem !important;
}
.confirmpaswd{
  padding-right: 1.5rem !important;
}
.cmpynamecss{
  padding-right: 1.5rem !important;
}

.cloushifu-logo {
  position: absolute;
  right: -12rem;
  bottom: -12rem;
}

.ellipse-circle {
  position: absolute;
  z-index: -1;
  right: -45rem;
  top: 18rem;
}

.user-profile-image {
  position: absolute;
  top: -2rem;
  right: -17rem;
}
.plat-loing-btn-body {
  text-align: center;
}

.plat-signin-button {
  border-radius: 6px;
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
  width: 100%;
  margin-top: 1rem;
}
.plat-signin-button1 {

  border-radius: 6px;
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  box-shadow: 0px 1px 2px 0px rgba(105, 81, 255, 0.05);
  color:white;
 
}
.icon-eye-class{

    font-size: 10px;
    color: black;
    margin-top: 2.3rem;

}

.sign-page-link {
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.plat-login-azure-btn {
  width: 80%;
  margin-bottom: 1rem;
  color: #000;
  background: #ffffff;
  border: 1px solid #000;
  font-size: 15px;
  font-weight: 500;
}
