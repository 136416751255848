.savingtab {
  margin: 2rem;
}
.savingtab .ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none !important;

  background-color: #2e2e2e !important;
  padding: 10px 10px !important;
  color: #ff6a2f;

  text-align: center;
  font-family: Poppins;
  font-size: 0.9375rem;
}
.savingtab .ant-table-wrapper .ant-table-tbody > tr > td {
  /* background-color: var(--white-color) !important; */
  /* color: #000; */
  background: black !important;
  color: #fff;
  /* border-bottom: 0.37px solid #848484 !important; */
  text-align: left;
  white-space: nowrap;
  border: 1px solid #848484;
  z-index: 0;
}
