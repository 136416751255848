.savop-card {
  height: 100%;
  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
  margin: 0.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
}

.savop-card.ant-card.active.ant-card-bordered {
  border: 1px #fd692f solid !important;
}
.savop-card .ant-card-body {
  padding: 0.4rem 0.7rem;
}
.savop-card h1 {
  /* font-size: 1rem; */
  font-size: 11px;
  color: white;
  margin: 0;
  padding: 0.3rem;
}
.savop-card h2 {
  /* font-size: 1.2rem; */
  font-size: 12px;
  color: #ff6a2f;
  padding: 0.3rem;
  margin: 0;
}
.savop-card h3 {
  /* font-size: 2rem; */
  font-size: 20px;
  color: #ff6a2f;
  margin: 0.3rem;
}

.savop-table-footer {
  background: #2e2e2e !important;
  /* color: #fff !important; */
  color: var(--orange-color);
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}

.savop-table-footer
  .ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  background: #2e2e2e !important;
  color: var(--orange-color);
}

.savop-table-footer
  .ant-table-cell.ant-table-cell-fix-right.ant-table-cell-fix-right-first {
  background: #2e2e2e !important;
}

.savop-table-footer .ant-table-cell:first-child {
  border-bottom-left-radius: 8px;
}
.savop-table-footer .ant-table-cell:last-child {
  border-bottom-right-radius: 8px;
}
.savop-table.ant-table-wrapper .ant-table-summary > tr > td {
  border: none !important;
  background: #2e2e2e;
}
.savop-table {
  min-width: 100%;
  width: 10rem;
}
.check-open {
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.check-open.open {
  opacity: 1;
  transform: translateY(0);
}

.savop-table.ant-table-wrapper .ant-table-thead > tr > td {
  color: #ff6a2f;
  text-align: center;
  border: none;
}

/* .savop-table .ant-table-row {
  cursor: pointer;
} */

.savop-table.ant-table-wrapper .ant-table-tbody > tr > td {
  background: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
  font-size: 0.75rem;
}

.savop-table tbody:first-of-type {
  display: block;
  max-height: 21vh;
  overflow: auto;
}
.w-20px {
  width: 50px;
}
.savop-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.savop-table .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem 0.4rem 0.5rem;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.savop-table .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}

.savop-table.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 0;
}

.savop-table .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.savop-table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
  color: white;
}

.assign-defer-form-datepicker.ant-picker .ant-picker-input > input,
.assign-defer-form-datepicker.ant-picker .ant-picker-input > input:focus,
.assign-defer-form-datepicker.ant-picker
  .ant-picker-input
  > input::placeholder {
  color: white;
}
.track-table-select-dropdown {
  width: fit-content !important;
}

.track-table-select {
  height: 1.8rem;
}
.track-table-select-dropdown.ant-select-dropdown .ant-select-item,
.track-table-select .ant-select-selection-item {
  font-size: 12px;
}

.savop-table.ant-table-wrapper .ant-table-summary {
  z-index: 0;
}

/* .savop-table.ant-table-wrapper
  .ant-table-tbody
  .ant-table-expanded-row
  > .ant-table-cell {
  padding: 0 0 0 0;
} */

.savop-table .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 1rem;
  padding: 0 0.5rem 0.4rem 0.5rem;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.savop-table .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 0;
  padding-bottom: 2rem;
  padding-top: 1rem;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #2e2e2e;
}

.savop-table
  .ant-table-pagination.ant-pagination
  .ant-pagination-options
  .ant-select {
  margin-right: 1rem;
}

.savop-table.ant-table-wrapper .ant-table-thead > tr > td,
.savop-table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 3rem;
  backdrop-filter: blur(5px);
  word-wrap: normal;
  border: none;
  text-align: center;
  font-size: 0.97rem;
}

.savops-tabs {
  width: 100%;
}
.savop-breadcrumb.ant-breadcrumb .ant-breadcrumb-link {
  font-size: 1rem;
  color: #ff6a2f;
  cursor: pointer;
}
.savop-breadcrumb.ant-breadcrumb .ant-breadcrumb-separator {
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
}
.savops-tabs.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  white-space: normal;
  justify-content: center;
}
.savops-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.savops-tabs .ant-tabs-tab-btn {
  color: #ff6a2f !important;
  padding: 0.5rem 4rem;
  font-size: 1.19425rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.83119rem !important;
}
.savops-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.savops-tabs .ant-tabs-tab,
.savops-tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: #1e1e1e !important;
}

.savop-table.ant-table-wrapper .ant-table {
  background: #202020;
  color: #ffffff;
  /* overflow-x: auto; */
  border-radius: 8px;
  margin-inline: 0 !important;
  margin-block: 0 !important;
}

.savop-table .ant-cell {
  padding: 16px 15px;
}

.savop-table .footer {
  text-align: right;
  color: #fff !important;
  background-color: transparent !important;
}

.savop-table .ant-table-wrapper .ant-table-footer {
  color: #fff !important;
  background: transparent !important;
  display: flex;
  justify-content: right;
}

.savops-cancel-btn {
  border-radius: 4px;
  border: 1px solid #787878;
  color: #fff;
  background-color: transparent !important;
  width: 35%;
}
.savops-submit-btn {
  /* background: #FF6A2F; */
  color: #fff;
  float: right;
  background: var(
    --3,
    linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)
  );
  width: 35%;
}

.end-defer-count.ant-badge {
  height: 100%;
  width: 100%;
}

.end-defer-count.ant-badge .ant-scroll-number .ant-scroll-number-only {
  transform: none;
}

.end-defer-count.ant-badge .ant-badge-count {
  transform: translate(25%, -25%);
  background: #ff6a2f;
}
