:root {
  --peems-site-color: #1c344c;
}

.filter-card {
  padding: 0.2rem 1.5rem;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
  color: #ff6a2f;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: underline;
  text-decoration-skip: spaces;
  margin-top: 25px;
}

.peems .ant-table-pagination.ant-pagination {
  background: #202020;
  margin-top: 0px;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}
.peems .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.peems .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
}
.peems .ant-pagination .ant-pagination-next .ant-pagination-item-link {
  display: flex;
  align-items: center;
  border: 1px solid #848484;

  padding: 0.5rem;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.peems
  .ant-pagination
  .ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-ellipsis {
  color: #fff !important;
  margin-left: 0px;
  border: 1px solid gray;
  margin-right: -8px;
}
.peems .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}
.peems .ant-select-selector {
  border-color: #848484 !important;
  background-color: transparent !important;
  color: #fff !important;
}
/*.job-card {
  display: flex;
  justify-content: center;
}*/
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
}
.repcards .cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 4rem;
}
.repcards .cards .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);

  min-width: 16.1rem;
  left: 1.5rem;
}

.cards .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);

  /* width: 15.1rem; */
}
.repcards .cards .ant-card .ant-card-head {
  border-radius: 8px 8px 0px 0px;
  background: rgba(255, 255, 255, 0.06);
  border: none;
  flex-direction: column;
  min-height: 3rem;

  display: grid;
  justify-content: center;
}
.cards .totaljobs-center .ant-card-head-title {
  text-align: center;
  padding-right: 2rem; /* Align the title in the center */
}
.cards .ant-card .ant-card-head-title {
  display: grid;
  justify-content: center;
}
.cards .ant-card .ant-card-body {
  padding: 4px;
  border-radius: 0 0 8px 8px;
}
/* .job-cards .ant-card-body {
  padding: 1.2rem 2rem;
} */

.cards .job-cards .job-card-initial {
  margin: 0 2rem 0 0;
}

.cards .total-job-count {
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-weight: 600;
}
.cards .job-status-count {
  color: #fff;
  text-align: right;
  font-family: Poppins;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  font-weight: 600;
}
.cards .job-status-icon,
.job-status-text {
  color: #fff;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;

  line-height: 29.299px; /* 153.333% */
}

.cards .job-status-icon svg {
  /* font-size: 20px;
width: 19px;
height: 19px;
flex-shrink: 0;*/
}

.cards .ant-card .ant-card-head {
  border-radius: 8px 8px 0px 0px;
  background: rgba(255, 255, 255, 0.06);
  border: none;
  flex-direction: column;
  min-height: 3rem;
}

.cards .ant-progress-outer {
  display: inline-block;
  width: 100%;
}
.cards .ant-progress .ant-progress-inner {
  position: relative;
  display: inline-block;
  min-width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #808080;
  border-radius: 100px;
}
.success .ant-progress .ant-progress-bg {
  background: #3d9d1b;
}
.cards .scheduled .ant-progress .ant-progress-bg {
  background: #ffd12f;
}
.triggered .ant-progress .ant-progress-bg {
  background: #fff;
}
.Failed .ant-progress .ant-progress-bg {
  background: #eb2424;
}

/*.cards .success  .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #808080;
  border-radius: 100px;
  
  
}
.cards .scheduled .ant-progress-inner{

  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: #808080;
  border-radius: 100px;
}

.cards .triggered .ant-progress-inner{
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;

  background-color: #808080;
  border-radius: 100px;
}
.cards .Failed .ant-progress-inner{
  
  background-color: #808080;

}*/
.cards .ant-progress-line {
  position: relative;
  width: 10rem;
  font-size: 14px;
  margin-inline-end: 8px;
  margin-bottom: 8px;
}
.peems.ant-table-wrapper .ant-table {
  background: var(
    --Tabular-Fill,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  ) !important;
  color: white !important;
  border-radius: 8.525px;

  border-bottom: none;

  /* overflow-x: auto !important; */
}

.peems.ant-table-wrapper .ant-table-thead > tr > th {
  background: #2e2e2e;
  color: #ff6a2f !important;
  padding: 17px 16px !important;
  border-bottom: transparent;
}

.peems.ant-table-wrapper .ant-table-thead > tr > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  text-align: start;
  background: #fafafa;
  border-bottom: none !important;
}
.peems.ant-table-wrapper .ant-table-thead > tr > td {
  background: #2e2e2e !important;
  color: var(--white-color) !important;
  border-bottom: none !important;
}
.peems.ant-table-wrapper .ant-table-tbody > tr > td {
  border-bottom: none !important;
  background: #202020 !important;
}

.job-schedule-table {
  height: 200px;
  overflow-x: auto;
}

/*tr.ant-table-expanded-row td.ant-table-cell {
  padding: 16px 0px;


} */

.job-schedule-table .ant-table-expanded-row td.ant-table-cell {
  background: grey !important;
  color: var(--white-color) !important;
  border: none !important;
}

.job-schedule-table table thead tr th {
  font-weight: 600;
  /* color: var(--white-color); */
  color:#ff6a2f
}
.job-schedule-table .ant-table-wrapper .ant-table tbody td {
  color: var(--white-color);
}

.peems-completed {
  display: flex;
  justify-content: center;

  /* border-radius: 4px; */
  color: #fff;
  padding: 2px 2px;
  background: #86bc0b;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;

  line-height: 23px;
  border-radius: 4.315px;
}

.peems-active {
  background-color: #fcaf04;
  display: flex;
  justify-content: center;

  /* border-radius: 4px; */
  color: #fff;
  padding: 2px 2px;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;

  line-height: 23px;
  border-radius: 4.315px;
}

.peems-failed {
  display: flex;
  justify-content: center;

  /* border-radius: 4px; */
  color: #fff;
  padding: 2px 2px;

  font-family: Poppins;
  font-size: 12px;
  font-style: normal;

  line-height: 23px;
  border-radius: 4.315px;
  background: #eb2424;
}
.peems-not-trigerred {
  display: flex;
  justify-content: center;

  /* border-radius: 4px; */
  color: #fff;
  padding: 2px 2px;
  background: #86bc0b;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;

  line-height: 23px;
  border-radius: 4.315px;
  background: #eb2424;
}
.peems-running {
  display: flex;
  justify-content: center;

  /* border-radius: 4px; */
  color: #fff;
  padding: 2px 2px;
  background: #86bc0b;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;

  line-height: 23px;
  border-radius: 4.315px;
  background: #2a87db;
}

.peems-color {
  color: white !important;
  border-bottom: none;
}
.peems-color > :not(:last-child) > :last-child > * {
  border-bottom: none;
}

.peems-color > tbody {
  vertical-align: inherit;
  border-bottom: transparent;
}

.jobconfig .react-js-cron {
  display: flex;
  color: #fff;
  /* justify-content: space-between; */
}

.jobconfig .ant-btn-dangerous.react-js-cron-clear-button {
  background-color: red;
}
h6.mb-4 {
  color: #ff6a2f;
  font-family: Poppins;
  font-size: 1.4375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  border-bottom: 2px solid #787878;
  padding-bottom: 1rem;
}

/*div.react-js-cron-select {
  margin-left: 5px;
  /* width: 114px; 
}*/

.jobconfig .ant-modal-content {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  width: 600px;
  margin-top: 15px;
}
.jobconfig .ant-form-vertical .ant-form-item-label > label {
  background-color: transparent;
}
.jobconfig .ant-modal-header {
  color: rgba(0, 0, 0, 0.88);
  /* background: #ffffff; */
  background: transparent;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
}
.jobconfig .ant-modal-title {
  background: transparent !important;
  color: #ff6a2f;
  font-family: Poppins;
  font-size: 1.437rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  border-bottom: 2px solid #787878;
  padding-bottom: 1rem;
}
.jonconfig .ant-btn-default {
  background-color: orange;
  border-radius: 5px;
  border: 1px solid #787878;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.jobconfig .ant-btn-primary {
  border-radius: 5px;

  color: white;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.jobconfig .ant-input {
  background-color: transparent;
}

.selectorantd .ant-select-selector {
  background-color: transparent;
  width: 100%;
}
.jobconfig .ant-input-group .ant-input-group-addon:last-child {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
  color: white;
}

.bgpopup .ant-modal-content {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}
.bgpopup .ant-form-vertical .ant-form-item-label > label {
  background-color: transparent;
}
.bgpopup .ant-modal-header {
  color: rgba(0, 0, 0, 0.88);
  /* background: #ffffff; */
  background: transparent;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
}

.bgpopup .ant-input {
  background-color: transparent;
  color: white;
}
.bgpopup .ant-modal-title {
  background: transparent !important;
  color: white;
  border-bottom: 2px solid #787878;
  padding-bottom: 1rem;
}
.bgpopup .ant-btn-default {
  background-color: transparent;
  border-radius: 5px;
  border: 1px solid #787878;
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bgpopup .ant-btn-primary {
  border-radius: 5px;
  background: var(
    --3,
    linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%)
  );
  color: #fff;
  font-family: Poppins;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.bgpopup .ant-select-selector {
  border-color: #848484 !important;
  background-color: transparent !important;
  color: #fff !important;
}

.bgpopup .ant-select-selection-search {
  background-color: transparent !important;
}
/*.table-add-button{
  border-radius: 5px;
background: var(--rt, rgba(255, 255, 255, 0.40));
}*/
.jobconfig .react-js-cron-error div.react-js-cron-custom-select {
  background: transparent;
}
.jobconfig
  .ant-select-single.ant-select-show-arrow
  .ant-select-selection-placeholder {
  padding-inline-end: 18px;
  color: white;
}
.jobconfig .ant-select-selection-placeholder {
  color: #fff !important;
}
.slm-ul-li {
  margin-right: 7rem;
}
.slm-ul-li .ant-picker {
  background-color: #4f4f4f;
  border: none;
  color: white !important;
}

.ant-modal .ant-modal-footer .ant-btn+.ant-btn{
  margin-bottom: 0;
  margin-inline-start: 8px;
  background-color: #ff6a2f;
}

.slm-color,
.slm-ul-li li,
.slm-ul-li li.active {
  color: #ff6a2f;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.slm-ul-li li.active {
  border-bottom: 2px solid #ff6a2f;
}

.ant-modal .ant-modal-footer .ant-btn+.ant-btn{
  margin-bottom: 0;
  margin-inline-start: 8px;
  background-color: #ff6a2f;
}

.jobconfig .ant-select-selector {
  border-color: #848484 !important;
  background-color: transparent !important;
  color: #fff !important;
}
.float-right {
  border-radius: 6px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));
  background: #2e2e2e;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: none;
  color: #fff;
  margin: 6px;
  padding: 7px 24px;
  float: right;
}

.slm-color,
.slm-ul-li li,
.slm-ul-li li.active {
  color: white;
}
/*.peems-graph .apexcharts-legend-text {
  
  font-size: 14px;
  color: aquamarine
}*/
