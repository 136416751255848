:root {
  --devops_site_color: #ff6a2f;
  --devops_background_color: #f8f8f8;
  --devops_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --card-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
}

.arrow-back svg {
  color: white;
}

.header-title-devops-main {
  font-size: 2.3rem;
  font-style: normal;
  font-weight: 600;
  color: var(--devops_site_color);
  padding: 1rem 2rem 1rem 0rem;
  margin-top: 10%;
  margin-bottom: 10%;
}

.ant-select-selection-placeholder {
  color: #898989 !important;
}

.devops-background .cams-tabs > .ant-tabs-nav {
  margin: 0px;
}

.header-title-devops {
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  color: var(--devops_site_color);
  /* padding: 1rem 2rem 1rem 1rem; */
}

.devops-background {
  /* margin-right: 4% !important;
  margin-left: 4% !important; */
}

.devops-background-inner {
  margin: 0;
}

.center-image {
  display: flex !important;
  align-items: center !important;
  height: 100% !important;
  width: 100% !important;
}

.center-text {
  display: flex;
  justify-content: center;
  color: #656565;
}

.devops_Table.ant-table-wrapper .ant-table-thead > tr > td {
  color: var(--white-color);
  text-align: center;
  border: none;
}

.devops_Table.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  color:white;
  border-bottom: transparent !important;
}

.devops_Table .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.devops_Table .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}
.devops_Table .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.devops_Table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
}
.devops_Table .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.devops_Table .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 5em;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.devops_Table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 10rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}

.devops_Table.ant-table-wrapper .ant-table {
  background-color: #202020;
  margin-top: 2.45rem;
  color: var(--devops_site_color);
  overflow-x: auto;
}

.ant-table-wrapper {
  /* min-width: 100%; */
}

.alert-detail-table2-width1 {
  overflow-y: auto;
}

.color-white{
  color:white;
}

.modal-btn {
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  border: none !important ;
}
/* .modal-icon {
  font-size: 18px !important;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
} */

.table-col-status {
  width: 100px;
  height: 32px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dev-approve-button {
  color: #3d9d1b;
  background-color: #202020;
  border: transparent;
}

.dev-approve-button:disabled {
  cursor: not-allowed;
  color: #848484 !important;
}

.approvel-view-cr {
  text-decoration: underline;
  cursor: pointer;
}
.dev-reject-button {
  background-color: #202020;
  color: #be1e2d;
  border: transparent;
}
.dev-reject-button:disabled {
  cursor: not-allowed;
  color: #848484 !important;
}

.modal-btn {
  display: flex;
  background-color: #fff !important;
  color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  cursor: pointer;
}
.modal-icon {
  font-size: 20px !important;
  color: var(--devops_site_color) !important;
}
.total-modal {
  display: flex;
  align-items: center;
}
.total-modal-text {
  display: flex;
  align-items: center;
  margin-left: 10px !important;
  margin-top: 2% !important;
}

.yes-btn {
  color: #fff !important;
  background-color: var(--devops_site_color) !important;
  border: none !important ;
  border-radius: 7px !important;
  float: left !important;
  cursor: pointer;
}
.previous-btn {
  color: #fff !important;
  background-color: var(--devops_site_color) !important;
  border: none !important ;
  border-radius: 7px !important;
  float: left !important;
  cursor: pointer;
  margin: 0.5rem;
}
.devops-table-header:hover .ant-table-column-sorters {
  color: #333;
  font-size: 13px;

}

.no-btn {
  background-color: #1f1f1f !important;
  color: white !important;
  border: 1px solid #ff6a2f !important ;
  border-radius: 7px !important;
  width: 7rem;
  margin-left: 10px;
  float: right !important;
  cursor: pointer;
}
.modal-tag {
  color: white !important;
}
.progress-text-line {
  padding: 0.25rem !important;
  font-size: 18px !important;
  text-align: right !important;
}
.title-btn-row {
  position: absolute;
  right: 0;
  top: -35px;
}
.title-btn-row1 {
  position: absolute;
  right: 1px;
  top: 77px;
}
.title-btn {
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  ) !important;
  color: #fff !important;
  min-width: 5.59306rem;
  height: 2.62806rem;
  flex-shrink: 0;
  border-radius: 0.6065rem !important;
  border: 1.078px solid var(--111, rgba(255, 255, 255, 0.2)) !important ;
  backdrop-filter: blur(5.390927791595459px);
  cursor: pointer;
}

.title-text {
  color: var(--devops_site_color) !important;
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  padding: 5px;
}
.head-text {
  padding: 8px;
  color: var(--devops_site_color) !important;
}
.tag-btn {
  width: 155px;
  text-align: center;
  cursor: pointer;
}
.progress-btn {
  border: none !important ;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6) !important;
  border-radius: 7px !important;
  width: 100% !important;
}
.cent-img {
  align-items: center !important;
  display: block;
  margin-left: 50%;
}

.devops-form-steps {
  background-color: transparent;
  width: 60%;
}

.devops-form-steps > .ant-steps-item-active::before {
  background-color: white !important;
}

.devops-form-steps.ant-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: white;
  font-size: 16px;
}

.devops-form-steps.ant-steps
  .ant-steps-item-wait
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #848484;
  cursor: pointer;
  font-size: 16px;
}
.devops-form-steps.ant-steps.ant-steps-navigation .ant-steps-item::after {
  display: none;
  content: "";
}

.devops-form-steps.ant-steps.ant-steps-small .ant-steps-item-icon {
  display: none;
}

.basic-details {
  background: var(--card-bg);
  backdrop-filter: blur(5px);
  border: none;
  padding: 5rem 0 5rem 0;
  margin-top: 6rem;
  border-radius: 12px;
}

.devops-form-names {
  color: var(--white-color);
  font-size: 1.1rem;
}
.devops-form-names1 {
  color: var(--white-color);
  font-size: 0.9rem;
}

.form-back-button {
  border-radius: 5px;
  border: 1px solid var(--devops_site_color);
  color: white;
  width: 10.25rem;
  background: transparent;
}

.yes-button {
  border-radius: 5px;
  margin-left: 10px;
  min-width: 7rem;
  background: #ff6a2f;
  color: white;
  user-select: none;
  border: none;
}

.display-button {
  border-radius: 5px;
  /* margin-left: 10px; */
  min-width: 7rem;
  background: #ff6a2f;
  color: white;
  user-select: none;
  border: none;
}


.save-form-button {
  border-radius: 5px;
  margin-left: 10px;
  width: 10.25rem;
  background: var(--devops_site_color);
  color: white;
}

.basic-details-cr-preview {
  background: var(--card-bg);
  backdrop-filter: blur(5px);
  border: none;
  padding: 4rem 0 5rem 0;
  margin-top: 1rem;
  border-radius: 12px;
}

.configuration-details {
  margin-top: 2rem;
  padding: 2rem 0 2rem 0;
}

.configuration-card-body {
  border-radius: 9px;
  border: none;
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);

  padding: 3rem 0 3rem 0;
  border-radius: 12px;
}

.devops-form-steps.ant-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  color: #848484;
}
.click-here-text {
  text-decoration: underline;
  color: var(--devops_site_color);
}

.additional-tag-modal .ant-modal-content,
.additional-tag-modal .ant-modal-header {
  background-color: #d9dee3;
}

.color-red {
  color: #be1e2d;
}

.heading-devops-config {
  font-weight: 500;
  font-size: 1.2rem;
  color: var(--white-color);
  justify-content: center;
}

.sub-heading-devops-config {
  font-weight: 400;
  color: var(--white-color);
  justify-content: center;
  font-size: 15px;
}

.devops-table .ant-table-thead th {
  /* min-width: 180px; */
}

.devops-preview-rows {
  border-bottom: 0.474px solid #84848484;
  padding: 16px;
}

.devops-antd-input,
.devops-antd-select {
  background-color: #0f0f0f;
  color: white;
  border-radius: 8px;
  width: 100%;

}
.slm-antd-input,
.slm-antd-select {
  background-color: #0f0f0f;
  border-radius: 8px;
  width: 34%;
  left: 25rem;
  bottom: 50px;
}

.status-box {
  padding: 4px 5px;
  text-align: center;
  border-radius: 5px;
  /* height: 1.51006rem; */
  width: 150px;
  cursor: pointer;
  color: var(--white-color);
}
.status-box.draft {
  background-color: #ffaa00;
}

.status-box.failed {
  background-color: #eb2424;
}
.status-box.approved {
  background-color: #86bc0b;
}
.status-box.approval-pending {
  background-color: #2a87db;
}
.status-box.completed {
  background-color: #86bc0b;
}

.devops-modal.ant-modal .ant-modal-content {
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.ant-form-vertical .comment-form-field .ant-form-item-label > label {
  background: transparent;
  color: #ff6a2f;
}
.comment-form-field textarea {
  /* background: transparent; */
  /* background:#433f3f !important; */
  color: white;
  border-color: white;
}

.devops-antd-input:focus,
.devops-antd-input:hover {
  background-color: #0f0f0f;
}

.devops-status .ant-modal-header .ant-modal-title {
  color: #ff6a2f;
}

.basic-details1-cr-preview {
  background: var(--card-bg);
  backdrop-filter: blur(5px);
  border: none;
  padding: 4rem 0 5rem 0;
  margin-top: 1rem;
  border-radius: 12px;

}
.landingzoneadd {
  background: var(--card-bg);
  backdrop-filter: blur(5px);
  border: none;
  padding: 3rem 0 3rem 0;
  margin-top: 3rem;
  border-radius: 12px;
  /* margin-left: 9rem;
  margin-right: 9rem; */
}
.landingzoneadd1 {
  background: var(--card-bg);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border: none;
  padding: 5rem 0 5rem 0;
  margin-top: 3rem;
  border-radius: 12px;
  margin-left: 1rem;
  margin-right: 1rem;
}
.form-back-button1 {
  border-radius: 5px;
  border: 1px solid var(--devops_site_color);
  color: white;
  width: 10.25rem;
  background: transparent;
}



.SaveBack{
  max-width: 91%;
}

.devops-antd-select1{
  background-color: #0f0f0f;
  color: white;
  border-radius: 8px;
  width: 100%;

} 

.devops-antd-select::placeholder {
  color: grey; /* Change this to your desired shade of grey */
  opacity: 1; /* Ensure it's fully visible */
}

.devops-antd-select:-ms-input-placeholder {
  color: grey;
}

.devops-antd-select::-ms-input-placeholder {
  color: grey;
}

.devops-form-names2{
  margin-left: -84px;
  color: var(--white-color);
  font-size: 0.99rem;
}

.ant-steps-item-title1.ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  padding: 26px;
}

.ant-steps-item-title1 .ant-tabs-nav-wrap{
  position: relative;
  display: flex;
  flex: auto;
  align-self: stretch;
  overflow: hidden;
  white-space: nowrap;
  transform: translate(0);
  margin-left: 39%;
}
/* .ant-tabs-nav-list{
  background-color:#0f0f0f !important ;
} */

.ant-steps-item-title1.ant-tabs .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  color:var(--devops_site_color);
}



.alert_table .ant-table-wrapper .ant-table-thead > tr > td {
  color: var(--white-color);
  text-align: center;
  border: none;
}

.alert_table.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}

.alert_table .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.alert_table .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}
.alert_table .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.alert_table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
}
.alert_table .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.alert_table .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 5em;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.alert_table .ant-table-wrapper .ant-table-thead > tr > th {
  color: white;
  background: #2e2e2e;
  min-height: 10rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}

.alert_table .ant-table-wrapper .ant-table {
  background-color: #202020;
  margin-top: 2.45rem;
  color: var(--devops_site_color);
  overflow-x: auto;
}
.landing-zone-footer{
  margin: 2rem !important;
  padding: 0rem 6rem !important;
}
