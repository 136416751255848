.migroot {
  margin: 1rem 2rem 2rem 2rem;
}

.cards5 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
}
.cards5 .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);

  /* width: 15.1rem; */
}
.cards5 .ant-card .ant-card-body {
  padding: 10px;
  border-radius: 0 0 8px 8px;
}
.cards5 .ant-card .ant-card-head-title {
  color: #ff6a2f;
  border: none;
  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
}
.cards5 .ant-card .ant-card-head {
  background-color: transparent;
  border: none;
  min-height: 3rem;
}
.cards .ant-form-item {
  margin-top: 1rem;
}
.selectst .ant-select-selection-placeholder {
  color: #fff !important;
}
.cards5 .cd {
  margin-bottom: 3rem;
}

h2.heading {
  color: #fff;

  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  text-align: center;
  line-height: normal;
  margin: 2rem;
}
.boxes {
  margin-top: 3rem;
  display: flex;
  justify-content: center;
}

.cards6 {
  margin-top: 2rem;
  margin: 2rem;
}

.cards6 .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);
}
.cards6 .ant-card .ant-card-head-title {
  color: #ff6a2f;

  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
}
.cards6 .ant-card .ant-card-head {
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-height: 56px;
  /* margin-bottom: -1px; */
  padding: 0 24px;
  /* color: rgba(0, 0, 0, 0.88); */
  font-weight: 600;
  font-size: 16px;
  /* background: transparent; */
  border-bottom: none;
  border-radius: 8px 8px 0 0;
}
.savingstab {
  margin: -2rem;
}

.savingstab .ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none !important;

  background-color: #2e2e2e !important;

  color: #ff6a2f;

  text-align: center;
  font-family: Poppins;
  font-size: 0.9375rem;
}

.savingstab .ant-table-wrapper .ant-table-tbody > tr > td {
  /* background-color: var(--white-color) !important; */
  /* color: #000; */
  background: black !important;
  color: #fff;
  /* border-bottom: 0.37px solid #848484 !important; */
  text-align: left;
  white-space: nowrap;
  border: 1px solid #848484;
  z-index: 0;
}

.savingstab .ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none !important;
}
.savingstab .ant-table-wrapper .ant-table thead th:nth-child(4) {
  /* min-width: 2rem !important; */
}
h6.head6 {
  color: #fff;
  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 2rem;
}
.savingtab th.ant-table-cell {
  border: 1px solid #848484;
}

.add-button-position {
  position: absolute;
  padding: 1rem;
}

button.add-btn {
  color: white;
  background: #ff6a2f;
  border: 2px solid #2b2b2b;
  padding: 10px 23px 28px 23px;
  border-radius: 10px;
}
