:root {
  --costimize_site_color: #141416;
  --costimize_background_color: #0f0f0f;
  --costimize_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --orange-color: #ff6a2f;
}
.ant-drawer-content.costdemaze-filter-drawer {
  height: 100%;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}
.costdemaze-filter-title {
  color: var(--orange-color);
  font-weight: 400;
  font-size: 11px;
}
.costdemaze-filter-sub-title {
  color: var(--white-color);
  font-weight: 400;
  font-size: 11px;
}
.costdemaze-filter-end-value {
  display: flex;
  justify-content: end;
}
.costdemaze-filter-center-value {
  display: flex;
  justify-content: center;
}
.costdemaze-filter-list-value {
  color: var(--white-color);
}
.costdemaze-filter-dropdown {
  font-size: 11px;
  font-weight: 400;
  width: 100%;
  background-color: transparent;
  border: 1px solid var(--orange-color);
  color: var(--white-color);
  padding-top: 7px;
}
.costdemaze-drawer-filter {
  height: 100%;
  overflow-y: scroll;
}
.costdemaze-drawer-filter.ant-drawer-right > .ant-drawer-content-wrapper {
  width: 280px !important;
}
.ant-drawer-right > .ant-drawer-content-wrapper {
  width: 280px !important;
}
.ant-dropdown {
  max-height: 300px !important;
}
.ant-drawer .ant-drawer-header {
  display: none;
}
.ant-dropdown .ant-dropdown-menu {
  position: fixed !important;
  /* width: 200px; */
  /* height: 260px; */
  /* overflow-y: scroll; */
  /* overflow-x: scroll; */
}
.fliter-text-size {
  font-size: 11px;
  font-weight: 400;
}
