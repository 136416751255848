.crd {
  background: #212121;
  padding: 22px;
  text-align: start;
  border-radius: 6px;
  border: 1px solid #353535;
  height: 100%;
  overflow: hidden;
}
.h-title {
  font-size: 13px;
  font-weight: 500;
  color: white;
  margin-bottom: 10px;
}
.s-title {
  font-size: 13px;
  font-weight: 600;
  color: #ff6c00;
}

.mk-graph-bg {
  position: absolute;
  bottom: -3px;
  left: 0;
  right: 0;
}

.custom-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: separate !important;
}

.custom-table th,
.custom-table td {
  border-bottom: 1px solid #212121;
  padding: 15px;
  background: #323232;
  text-align: center;
  color: white;
}

.custom-table th {
  background-color: #f16a2f;
  color: white;
  font-weight: 400;
  font-size: 13px;
}
.br-1 {
  border-radius: 7px 0px 0px 0px;
}
.br-2 {
  border-radius: 0px 7px 0px 0px;
}

.h-auto {
  height: auto !important;
}
.sankey-chart-container {
  width: 100%;
}
