:root {
  --slm_site_color: #1c344c;
  --slm_background_color: #f8f8f8;
  --slm_lite_site_color: #e7e7e7;
  --white-color: #fff;
}
.slm-sidebar,
.slm-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}
.quickglancetitle .slm-color {
  color: #ff6a2f;

  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
}
.cd-accordion--animated-vs .slm {
  border-radius: 12px;
  margin: 0px !important;
  padding: 0px !important;
  background: #fff !important;
  box-shadow: none !important;
}
.slm-tabs .ant-tabs-tab-btn {
  color: #fff !important;
  padding: 0rem 3rem;
  font-size: 1.19425rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.83119rem !important;
}
.slm-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.slm-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_site_color) !important;
  padding-top: 16px;
  color: var(--white-color);
}

.mobile-slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_lite_site_color) !important;
  padding-top: 70px;
  color: var(--white-color);
}
.slm-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}

.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  color: var(--slm_site_color) !important;
  background-color: var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_site_color) !important;
  position: relative;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_lite_site_color) !important;
  position: relative;
}
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--slm_background_color);
}

/* compliance */
.card-header {
  width: 90%;
  text-align: center;
  border-bottom: none !important;
}
.card-options {
  width: 10%;
  text-align: end;
  margin-top: 4px;
}

.card-filter-icon {
  padding: 1px 10px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
  margin-left: -2rem;
}

/* .initalresponse_table_width > .ant-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content {
  overflow-x: scroll !important;
  width:fit-content;
} */

/* .initalresponse_table_width > .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  overflow-x: scroll !important;
} */

/* .ant-spin-nested-loading .ant-spin-container > .ant-table-wrapper .ant-table > .ant-table-wrapper .ant-table-container > .ant-table-content {
  overflow-x: scroll !important;
} */

.projectStatus-body-screen {
  margin-right: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}
.slm-sidebar,
.slm-sidebar {
  height: 100vh !important;
  position: fixed !important;
  border: none !important;
}

.slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_site_color) !important;
  padding-top: 16px;
  color: var(--white-color);
}

.mobile-slm-sidebar > .ps-sidebar-container {
  background-color: var(--slm_lite_site_color) !important;
  padding-top: 70px;
  color: var(--white-color);
}
.slm-sidebar > .ps-sidebar-container > .ps-menu-root {
  font-size: 20px !important;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root {
  height: 65px !important;
}

.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button {
  margin-top: 6px;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root
  > .ps-menu-button:hover {
  color: var(--slm_site_color) !important;
  background-color: var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_site_color) !important;
  position: relative;
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root:hover {
  background-color: var(--slm_background_color) !important;
  color: var(--slm_lite_site_color) !important;
  position: relative;
}
/* .sidebar-budget-without-white:hover{
    display: none;
}
.sidebar-budget-with-white:hover{
    display: block;
} */
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::before {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: -46px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-top-right-radius: 25px;
  box-shadow: 0 -25px 0 0 var(--slm_background_color);
}
.slm-sidebar
  > .ps-sidebar-container
  > .ps-menu-root
  > .css-ewdv3l
  > .ps-menuitem-root.active::after {
  content: "";
  position: absolute;
  background-color: transparent;
  bottom: 65px;
  height: 46px;
  width: 16px;
  left: 48px;
  border-bottom-right-radius: 25px;
  box-shadow: 0px 25px 0px 0px var(--slm_background_color);
}

/* compliance */
.card-header {
  width: 90%;
  text-align: center;
  border-bottom: none !important;
}
.incident {
  color: #fff;
  margin-right: 18rem;
}
.head-color {
  color: #ffff;
}
.card-options {
  width: 10%;
  text-align: end;
  margin-top: 4px;
}
.iconedit {
  color: white;
  float: right;
  border: 2px solid #2e2e2e;
  padding: 3px;
  margin-right: 2rem;

  backdrop-filter: blur(5.933259010314941px);
}
.change-trends {
  padding: 0% 2%;
}
.slmcard {
  box-sizing: border-box;
  margin-top: 2rem;
  min-height: 87%;
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;

  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
}
.slmcard .ant-card-body {
  padding: 12px;
}
.reso {
  margin-top: 0rem;

  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;

  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
}
.reso .ant-card-body {
  padding: 0px;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
}

.card-filter-icon {
  padding: 1px 10px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
}

/* .initalresponse_table_width > .ant-table .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container .ant-table-content {
  overflow-x: scroll !important;
  width:fit-content;
} */

/* .initalresponse_table_width > .ant-table-wrapper .ant-spin-nested-loading .ant-spin-container {
  overflow-x: scroll !important;
} */

/* .ant-spin-nested-loading .ant-spin-container > .ant-table-wrapper .ant-table > .ant-table-wrapper .ant-table-container > .ant-table-content {
  overflow-x: scroll !important;
} */

.projectStatus-body-screen {
  margin-right: 4.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 5rem;
}

tr.assetmanaged-table-footer > td {
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  white-space: nowrap;
}
.assetmanaged-table-footer {
  background: #202020 !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;

  text-align: center !important;
}

tr.assetmanaged-table-footer-new > td {
  background: #aec9ec !important;
  color: var(--white-color) !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  border: none !important;
  text-align: center !important;
}

.assetmanaged-table-footer > .ant-typography {
  color: var(--white-color) !important;
}

.asset_managed_table > .ant-table-wrapper > .ant-table-summary {
  z-index: 0 !important;
}
tr.assetmanaged-table-footer-new > .ant-typography {
  color: var(--white-color) !important;
}

/* .assetmanaged-table-footer td:last-child {
  border-bottom-right-radius: 10px;
}  */

/* .ant-table-wrapper .ant-table thead th {
  color: var(--white-color) !important;
  background: var(--slm_site_color) !important;
  white-space: nowrap !important;
} */

/* .ant-table-wrapper .ant-table thead th:nth-child(4) {
  width: 4rem !important;
}*/

.slm.ant-table-wrapper .ant-table thead th::before {
  display: none !important;
}

/* .slm.ant-table-wrapper .ant-table-thead > tr > th {
  border-bottom: none !important;

  padding: 10px 16px !important;
} */

.slm.ant-table-wrapper .ant-table tbody td {
  font-size: 12px !important;
}
/* .ant-table-wrapper .ant-table-cell-fix-left{
  background: black !important;
} */
.slm.ant-table-wrapper .ant-table-tbody > tr > td {
  /* background-color: var(--white-color) !important; */
  /* color: #000; */
  background: rgba(128, 128, 128, 0.459);
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 100% !important;
  border-bottom: 0.37px solid #848484 !important;
  text-align: left;
  white-space: nowrap;
  border: none;
  z-index: 0;
}

.role-table-icon {
  font-size: 18px;
  margin: 0px 4px;
}
.slm {
  width: 100% !important;
}
.slm.ant-table-wrapper .ant-table-thead > tr > td {
  color: var(--white-color);
  text-align: center;
  border: none;
}

.slm.ant-table-wrapper .ant-table-thead > tr > th {
  color: #ff6a2f;
  background: #2e2e2e;
  min-height: 10rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}
.slm.ant-table-wrapper .ant-table {
  background-color: #202020e1 !important;
  color: var(--spp_site_color) !important;
}
.slm.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020e1;
  /* border-top: 0.474px solid #84848484; */
  border-bottom: 1px solid rgba(255, 255, 255, 0.178) !important;
  text-align: center;
  color: white;
}
.slm .ant-table-pagination.ant-pagination {
  margin: 0px;
  background: #202020 !important;
  padding: 1rem;
}
.slm .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.slm .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.slm .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}
.slm .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.slm .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
}
.slm.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:last-child {
  /* border-start-end-radius: 8px; */
  background: #2e2e2e;
}

.slm.ant-table-wrapper
  .ant-table-container
  table
  > thead
  > tr:first-child
  > *:first-child {
  border-start-start-radius: 8px;
  background: #2e2e2e;
}
.slm-bg {
  background-color: #2e2e2e;
}
.plat-asset-spend-body-vs.slmtable {
  color: #fff;
  background: #202020;
}
/* .antmodalslm .ant-modal-content {
  background: red !important;
} */
.slmmodalchart .ant-modal-content {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);

  margin-top: 15px;
}
.slmmodalchart .ant-modal-title {
  background: #202020;
  color: #ff6a2f;
  font-family: Poppins;
  font-size: 1.437rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  text-align: center;
  padding-bottom: 1rem;
}

.slmmodal .ant-modal-content {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  /* min-width: 49rem; */
  margin-top: 15px;
}
.slmmodal .ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  color: #ffff;
  /* border-bottom: 3px solid #ff6a2f; */
  /* border-color: #ff6a2f; */
  margin: 0px 5px 0px 4px;
  /* text-align: center; */
  /* font-size: 1.25rem; */
  /* width: 10rem; */
}
.slmmodal .ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  color: #ffff;
  /* border-bottom: 3px solid #ff6a2f; */
  /* border-color: #ff6a2f; */
  margin: 0px 5px 0px 4px;
  /* text-align: center; */
  /* font-size: 1.25rem; */
  /* width: 10rem; */
}
.slmpopupchart {
  background: transparent;
  /* background: #ffffff; */
  box-shadow: none;
}
.slmmodal .ant-modal-title {
  background: #202020;
  color: #fff;
  font-family: Poppins;
  font-size: 1.437rem;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  border-bottom: 2px solid #787878;
  padding-bottom: 1rem;
}
.slmmodal .ant-modal .ant-modal-header {
  background: transparent;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
}
.slmmodal .ant-form-vertical .ant-form-item-label > label {
  background-color: transparent;
  color: #fff;
}
.slmmodal .ant-input {
  background-color: transparent;
  /* border-color: #a19e9e; */
  color: #fff;
}
.slmmodal .ant-select-selector {
  border-color: #848484 !important;
  background-color: transparent !important;
  color: #fff !important;
}
.slmmodal .ant-picker {
  box-sizing: border-box;
  margin: 0;
  padding: 4px 11px 4px;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1;
  list-style: none;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  position: relative;
  display: inline-flex;
  align-items: center;
  background: transparent;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  transition: border 0.2s, box-shadow 0.2s;
}
.slmmodal .react-datepicker__input-container input {
  background-color: transparent;
  color: #fff;
}
.slmmodal .ant-picker .ant-picker-input > input {
  color: #fff;
}
.slmmodal .form-control {
  min-height: auto;
  padding-top: 4px;
  padding-bottom: 3.28px;
  transition: all 0.1s linear;
  background: transparent;
}
.slmpnp-tabs .ant-tabs-tab.ant-tabs-tab-active > .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.slmpnp-tabs .ant-tabs-tab-btn {
  color: #848484 !important;
  padding: 0rem 3rem;
  font-size: 1.19425rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 1.83119rem !important;
}
.slmpnp-tabs > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list {
  background: transparent;
}
.rto {
  color: #fff;
}
.m-3.actionitems {
  color: #fff;
}
.slm-color,
.slm-ul-li li,
.slm-ul-li li.active {
  color: white;
}
.slm-colors {
  color: #fff;

  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.76038rem;
}

.spend-chart-titleslm {
  font-weight: 500;
  font-size: 15.24px;
  line-height: 100%;
  margin-bottom: 0px !important;
  margin-top: 0.7rem;
  color: #fff;
  text-align: center;
  margin-left: 4%;
}
.slmdate .slmdatepicker li.active {
  color: #ff6a2f;
  border-bottom: 2px solid #ff6a2f;
  padding: 1px;
}
.first-two-li {
  border: 1px solid #000;
  display: inline-block;
  margin-right: 10px;
  padding: 5px 10px;
  height: 100%; /* Set the height to match other elements */
  vertical-align: middle;
}

.ant-picker.antd-date-picker {
  border-radius: 0.25rem;
  border-color: 1px solid #bdbdbd;
}
.sidemenu-toggle {
  background: var(--slm_site_color);
  color: #fff;
  width: 30px;
  height: 30px;
  padding: 3px;
  border-radius: 7px;
}

.slm .ant-table-header.ant-table-sticky-holder {
  z-index: 0;
}

.compliance-dot {
  height: 17px;
  width: 17px;
  background-color: #84aee7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
  color: #fff;
}
.non-compliance-dot {
  height: 17px;
  width: 17px;
  background-color: #ffeaa0;
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
}

.text-white {
  color: #fff;
}

.card-header-color {
  color: #848484;
}
.slm-addform {
  float: right !important;
}

.collapse-header .ant-collapse-header-text,
.collapse-header .ant-collapse-expand-icon span svg {
  color: var(--site_color);
  font-size: 18px;
  font-weight: 600;
}

.collapse-ui-list {
  list-style: disc;
  line-height: 2;
}
.card-value-2 {
  font-size: 8px !important;
  line-height: 1rem !important;
}
.plat-card-12 {
  height: 100%;
  border-radius: 9px;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  width: 100%;
}

.slmul-li {
  color: white;
  /* list-style-type: circle; */
}
ul.ulslm {
  list-style-type: disc;
}
.slm-body-screen {
  margin-top: 7.5rem;
  margin-left: 1.6rem;
  padding: 0px 2.3rem;
  width: 100%;
  margin-bottom: 2rem;
  margin-right: 1.6rem;
}
.quickglancetitle .header-logo-block {
  text-align: end;
  margin-right: 15rem;
}
.assetspendslm .cd-accordion__item {
  -webkit-user-select: none;
  user-select: none;
  background-color: #202020;
}
.assetspendslm .asset-spend-title-vs {
  font-weight: 600;
  font-size: 12.24px;
  line-height: 18px;
  padding-left: 16px;
  display: flex;
  align-items: center;
  padding: 20px;
}
.slmdate {
  text-align: end;
  width: 84%;
  margin-top: 1rem;
}
.slmdate li {
  position: relative;
  cursor: pointer;
  padding: 0px;
  margin-right: 10px;
}
.slmdate li.active {
  color: #ff6a2f;
  border-bottom: 2px solid #ff6a2f;
}
.slmdate li.active::before,
.slmdate li.active::after {
  background-color: #000; /* Add your desired background color here */
  border: 2px solid #fff; /* Add your desired border style here */
  border-radius: 4px; /* Add your desired border radius here */
}
.slm-header-body {
  width: 100%;
  position: fixed;
  margin-left: 2.6rem;
  padding: 0 6.9rem 0 2.3rem;
  z-index: 1;
  background-color: var(--costimize_background_color);
}
.spend-searchslm {
  display: flex;
  position: fixed;
  z-index: 1;
  margin-top: 1rem;
  right: 5rem;
}
.plat-icon-bgslm {
  /* background: linear-gradient( 0deg, rgba(31, 31, 31, 0.94) 0%, rgba(31, 31, 31, 0.94) 100% ), linear-gradient( 180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 468.03% ); */
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  color: #ffff;
  border: 1px;
  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
  border-radius: 2px;
  padding: 1px 9px;
  /* width: 40px; */
  height: 27px;
  margin: 0 8px;
  backdrop-filter: blur(5px);
}
.plat-icon-bgslmdot {
  color: #ffff;

  /* box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6); */
  border-radius: 2px;
  padding: 1px 9px;
  /* width: 40px; */
  height: 27px;
  margin: 0 8px;
  backdrop-filter: blur(5px);
}
.forminput .ant-input-affix-wrapper {
  background-color: #2e2e2e;
  color: #fff;
}
.forminput .ant-input {
  background-color: #2e2e2e;
  border-color: 1px solid #a19e9e;
  color: #fff;
}
.card-optionsspend {
  float: right;
}

.slm-a2crover .row-card-header {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  color: white;
  padding: 15px 5px;
}
.slm-a2crover .light-header {
  color: #787878;
  font-size: 9.81px;
  font-style: normal;
  font-weight: 400;
}
.slm-a2crover .center-header {
  color: #ff6a2f;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
}
.slm-a2crover .light-sub-header {
  color: #fff;
  text-align: center;
  font-size: 12.75px;
  font-style: normal;
  font-weight: 500;
}
.sidebaricon {
  margin-right: 1rem; /* Adjust the margin as needed */
}
.card-filter-iconbutton {
  padding: 1px 10px;
  box-shadow: 0px 4px 4px rgba(217, 217, 217, 0.6);
  border-radius: 7px;
  background: transparent;
  margin-left: 17rem;
}
