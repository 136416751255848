.chatbot {
  z-index: 1000;
  position: relative;
  float: right;
}
.bottom-count {
  text-align: right;
  font-size: 12px;
  margin: 10px;
}
.chatbutton {
  z-index: 1000;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  transition: all 0.3s ease-in-out;
  color: #ffffff;
  background-color: #141416;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.chat-icon {
  width: 30px;
  height: 30px;
}

.bottom-count-success {
  margin-right: 4px;
  background: #5cb58c;
  color: #5cb58c;
  height: 12px;
  width: 12px;
  top: 1px;
  right: -9px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 28px;
  position: absolute;
}
.bottom-count-danger {
  margin-right: 4px;
  background: #f32013;
  color: #f32013;
  height: 12px;
  width: 12px;
  top: 1px;
  right: -9px;
  border-width: 0px;
  border-color: rgb(255, 255, 255);
  border-radius: 28px;
  position: absolute;
}
.chatbutton:hover {
  transform: scale(1.1);
}

.options {
  padding-bottom: 15px;
}

.options-header {
  text-align: center;
  font-size: 1rem;
  font-weight: normal;
}

.options-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.option-item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 15px;
  width: 130px;
  height: 40px;
  border: 1px solid #141416;
  border-radius: 15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  margin-bottom: 10px;
  /* box-shadow: inset 0px 0px 0px #ff8533; */
  transition: border 0.5s;
  cursor: pointer;
}

.option-item:hover {
  transition: border 0.5s;
  border: 3px solid #141416;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #141416;
  width: 100%;
  height: 15%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.header-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 15px;
  width: 130px;
  height: 40px;
  border: 1px solid #141416;
  border-radius: 15px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  /* box-shadow: inset 0px 0px 0px #ff8533; */
  transition: border 0.5s;
  cursor: pointer;
}

.header-btn:hover {
  transition: border 0.5s;
  border: 3px solid #141416;
}

.header-text {
  color: #ffffff;
  padding-left: 10px;
  margin: 10px;
}

.history-icon {
  color: #ffffff;
  padding-right: 10px;
}

.chatbot .react-chatbot-kit-chat-container {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  position: fixed;
  bottom: 80px;
  right: 20px;
  width: 400px;
  border-radius: 20px;
}

.chatbot .react-chatbot-kit-chat-inner-container {
  border-radius: 20px;
  height: 552px;
}

.chatbot .react-chatbot-kit-chat-message-container {
  background-color: #ffffff;
  overflow: unset;
  overflow-y: scroll;
}

.chatbot .react-chatbot-kit-chat-input-container {
  border-bottom-left-radius: 20px;
}

.chatbot .react-chatbot-kit-chat-input-form {
  border-bottom-left-radius: 20px;
}

.chatbot .react-chatbot-kit-chat-input {
  border-bottom-left-radius: 20px;
}

.chatbot .react-chatbot-kit-chat-header {
  background-color: #0085b0;
  color: white;
  border-radius: 0%;
}

.chatbot .react-chatbot-kit-chat-btn-send {
  border-radius: 0%;
  background-color: #008ffb;
  border-bottom-right-radius: 20px;
}

.chatbot .react-chatbot-kit-chat-btn-send-icon {
  transition: all 0.2s ease-in-out;
}

.chatbot .react-chatbot-kit-chat-btn-send-icon:hover {
  transform: scale(1.3);
}

.chatbot .react-chatbot-kit-chat-bot-avatar-container {
  background-color: #eaeef3;
}

.chatbot .react-chatbot-kit-chat-bot-avatar-container svg {
  color: #141416 !important;
}

.chatbot .react-chatbot-kit-chat-bot-message {
  color: #fff;
  margin-left: 0%;
}

.chatbot .react-chatbot-kit-user-chat-message {
  background-color: #eaeef3;
  color: #000;
}

.chatbot .react-chatbot-kit-user-chat-message-arrow {
  border-left: 8px solid #eaeef3;
}
.chatbot .react-chatbot-kit-user-avatar-container {
  background-color: #141416;
}
.chatbot .react-chatbot-kit-user-avatar-icon {
  fill: #eaeef3;
}

/* LOADER */

.bouncing-loader {
  display: flex;

  justify-content: center;

  align-items: center;

  margin-top: 5px;
}

.dots {
  background: rgb(248, 247, 247);

  height: 9px;

  width: 9px;

  border-radius: 50%;

  margin: 3px;

  animation: animate 0.8s infinite linear;
}

.dots:nth-child(1) {
  animation-delay: 0.2s;
}

.dots:nth-child(2) {
  animation-delay: 0.5s;
}

.dots:nth-child(3) {
  animation-delay: 0.8s;
}

.chatbot-header-icon {
  position: absolute;
  cursor: pointer;
  right: 32px;
  color: #fff;
  top: 30px;
  font-size: 21px;
}

@keyframes animate {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* MEDIA QUERY RESPONSIVE */

/* media query */
@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}
@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}
@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}
@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}
@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}
@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}
@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}
@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}
@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}
@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}
@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
}
@media only screen and (max-width: 768px) and (min-width: 427px) {
  .chatbot .react-chatbot-kit-chat-container {
    width: 350px;
  }

  .header-text {
    font-size: 12px;
  }

  .header-btn {
    width: 120px;
    font-size: 11px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .chatbot .react-chatbot-kit-chat-container {
    width: 350px;
    bottom: 60px;
  }

  .chatbutton {
    width: 40px;
    height: 40px;
    bottom: 10px;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .chatbot .react-chatbot-kit-chat-container {
    width: 300px;
    bottom: 60px;
  }
  .chatbutton {
    width: 40px;
    height: 40px;
    bottom: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .chatbot .react-chatbot-kit-chat-container {
    width: 300px;
    bottom: 60px;
  }
  .chatbutton {
    width: 40px;
    height: 40px;
    bottom: 10px;
  }
}
