.site-form-in-drawer-wrapper {
  position: absolute;
  right: 0px;
  bottom: 0px;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  background: #fff;
  border-top: 1px solid #e9e9e9;
}

.drawer-delete-icon {
  font-size: 21px;
  color: #fff;
  cursor: pointer;
}
