.informationBox {
  padding: 20px;
  border-radius: 3px;
  background-color: #fff;
  position: absolute;
  top: 0;
  right: 30rem;
  width: 100%;
  max-width: 350px;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  height: 36rem;
  overflow-y: auto;
}

.closeMessageBoxIcon {
  width: 20px;
  height: 20px;
  fill: red;
}
.informationBoxLogo {
  width: 30px;
  height: 30px;
  fill: #56af8c;
}

.closeMessageBox {
  position: absolute;
  right: 10px;
  top: 11px;
  border: none;
  color: red;
  font-size: 1.2rem;
  cursor: pointer;
}

.prompts_title {
  font-size: 16px;
}
.button_6 {
  align-items: center;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  position: relative;
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
  margin: 5px 0px;
}

.button_6:hover,
.button_6:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button_6:hover {
  transform: translateY(-1px);
}

.button_6:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

.icon_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
}

.icon_container .icon {
  cursor: pointer;
}

/* .button_7 {
align-items: center;
background-color: #ffffff;
border: 1px solid rgba(0, 0, 0, 0.1);
border-radius: 0.25rem;
box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
box-sizing: border-box;
color: rgba(0, 0, 0, 0.85);
cursor: pointer;
display: inline-flex;
font-size: 12px;
font-weight: 600;
justify-content: center;
line-height: 1.25;
margin: 0;
min-height: 3rem;
padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
position: relative;
text-decoration: none;
transition: all 250ms;
user-select: none;
-webkit-user-select: none;
touch-action: manipulation;
vertical-align: baseline;
width: 100%;
margin: 5px 0px;
} */

.button_7 {
  text-align: left;
  background-color: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.02) 0 1px 3px 0;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.85);
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.25;
  margin: 0;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 0px);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: 100%;
  margin: 5px 0px;
}

.button_7:hover,
.button_7:focus {
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  color: rgba(0, 0, 0, 0.65);
}

.button_7:hover {
  transform: translateY(-1px);
}

.button_7:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .informationBox {
    right: 370px;
    max-width: 300px;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .informationBox {
    width: 350px;
    height: 510px;
    bottom: 60px;
    right: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .informationBox {
    width: 300px;
    height: 510px;
    bottom: 60px;
    right: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
  }
}

@media only screen and (max-width: 320px) {
  .informationBox {
    width: 300px;
    height: 510px;
    bottom: 60px;
    right: 0px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: none;
  }
}
