.qg-custom-tabs .ant-tabs-tab {
  padding: 1px 25px 12px 25px;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 14px;
  color: rgb(255 255 255 / 88%);
}
.qg-custom-tabs .ant-tabs .ant-tabs-tab:hover {
  color: #ff6a2f;
}

.qg-custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #ff6a2f !important;
}
.qg-custom-tabs .ant-tabs-ink-bar-animated {
  /* background: #1c344c !important; */
  background-color: #ff6a2f !important;
}
.qg-custom-tabs .ant-tabs-tab:hover {
  color: #ff6a2f;
}
