:root {
  /* --costimize_site_color: #433f3f; */
  --costimize_site_color: #141416;
  --costimize_background_color: #0f0f0f;
  --costimize_lite_site_color: #e7e7e7;
  --white-color: #fff;
  --orange-color: #ff6a2f;
}

.banner-bg {
  background-image: url("../../assets/images/landing/banner-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
}

img .banner-img {
  height: 1rem;
}
.sign-in-button {
  background: "yellow";
  border-radius: 8px;
  border: 1px solid #fff;
  background-color: transparent;
  color: #fff;
  margin-right: 15px;
  padding: 5px;
  cursor: pointer;
  width: 130px;
}

.sign-up-button {
  color: #fff;
  width: 130px;
  font-size: 15px;
  font-style: normal;
  cursor: pointer;
  line-height: 120%;
  letter-spacing: 1.65px;
  border-radius: 8px;
  border: none;
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  padding: 5px;
}
.sign-up-button1 {
  
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);

}

.banner-text {
  color: var(--orange-color);
}

.secondary-text {
  color: var(--white-color);
}
.secondary-text-bottom {
  color: var(--white-color);
  padding: 5px;

  width: 250px;
}

a .secondary-text-bottom {
  color: #0f0f0f;
}
.secondary-text-2 {
  color: var(--white-color);
  font-weight: 700;
}

.sub-para {
  padding: 5%;
}

.position-relative {
  position: relative;
}

.ban-position {
  position: absolute;
  top: 10px;
  left: 30px;
}

.signin-home {
  margin: 5px;
}

.signin-home-1 {
  margin: 5px;
  margin-left: 3%;
}
.line-img {
  margin-bottom: 10px;
}
.arrow-icon {
  margin-left: 5px;
}
.footer-text {
  background: #1d1d1d !important;
  color: #e0e0e0;
  font-size: 16px;
}

.footer {
  display: flex;
  justify-content: space-between;
  background: #1d1d1d !important;
  /* background-color: #333;  */
  color: #fff; /* Choose your desired text color */
  padding: 20px;
}

.footer-column {
  flex: 1;
  margin-right: 20px;
}

.footer-heading {
  font-weight: bold;
  margin-bottom: 10px;
}
.footer-body {
  color: #e0e0e0;
}
.social-icon {
  background-color: #e0e0e0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #1d1d1d !important;
  margin-right: 10px;
  cursor: pointer;
}
.social-icons {
  display: flex;
  align-items: center;
}
.social-icon a {
  color: #1d1d1d !important;
  text-decoration: none;
  font-size: 20px;
}
.social-icon:hover {
  background: var(--orange-color) !important;
}
.soc-icon {
  font-size: 24px !important;
}
