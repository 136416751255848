.navHeader.navbar {
}

.nav-header-bg {
  background-repeat: no-repeat;
  background-size: cover;
}

.nav-circles {
  background: url("../../assets/images/landing/landing-circle-bg.svg") no-repeat;
  background-repeat: no-repeat;
  background-size: cover;

  background-repeat: no-repeat;
  background-size: 98.15vw 70.9em;
  height: 11vh;
  padding-top: 1.5rem;
}

.navHeader.navbar button.signup-button {
  border-radius: 10px;
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  padding: 0.5rem 3rem;
  color: white !important;
}

.para-text-1 {
  color: white;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
}

.para-text-2 {
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.cloudshifu-text {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
}
.cloudshifu-underline img {
  position: relative;
  bottom: 15px;
  width: 20rem;
}

.cloudshifu-book-demo button {
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  color: white;
  padding: 0rem 2.5rem;
  border: none;
  font-size: 17px;
  margin-top: 2rem;
}

.plat-sign-login-tap {
  background: #f5faff;
  border-radius: 5px;
  margin-right: 19px;
  height: 100%;
  /* height: 25rem; */
  overflow-y: auto;
  width: 30rem;
}
