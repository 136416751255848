.terms-background {
  background-color: white;
  min-height: 100vh;
}

.terms-heading {
  color: #1c344c;
  font-family: Poppins;
  font-size: 1.5rem;
  font-style: normal;
  padding: 2rem 0 0 2rem;
  font-weight: 600;
}

.terms-content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  margin: 16px;
}

.terms-sub-heading {
  font-size: 18px;
  font-weight: 550;
}

.terms-box {
  box-shadow: 0px 4px 4px 0px rgba(217, 217, 217, 0.6);
  width: 85%;
  height: 90%;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms-body {
  width: 95%;
  height: 95%;
  border-radius: 7px;
  overflow: auto;
  padding-right: 16px;
  margin-left: 3rem;
}

.terms-body::-webkit-scrollbar-thumb {
  background-color: #1c344c;
  border-radius: 5px;
  height: 0.7rem;
}

.terms-body::-webkit-scrollbar {
  background-color: #fff;
  width: 7px;
}

.terms-and-conditions {
  color: #000;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 184.5%;
  margin: 0;
}
