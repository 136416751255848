.assets-perspective-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: center;
}

.assets-perspective-card {
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0) 468.03%);
  height: 6.5rem;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.assets-perspective-card.ant-card .ant-card-body {
  padding: 1.3rem 0;
  border-radius: 0 0 8px 8px;
}

.assets-perspective-card .assets-header {
  color: white;
  display: flex;
  justify-content: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  padding-bottom: 1rem;
}

.assets-perspective-card .count {
  color: #ff6a2f;
  display: flex;
  justify-content: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  /* 92% */
  /* padding: 1rem; */
}

/* filter */
.filter-cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
}

.filter-cards .ant-card {
  border-radius: 0.5625rem;
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2));

  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  );
  backdrop-filter: blur(5px);
}

.filter-cards .ant-card .ant-card-body {
  padding: 0px 10px 10px 10px;
}

.filter-cards .ant-card .ant-card-head {
  border: none;
  color: #ff6a2f;
  text-align: center;
  font-family: Poppins;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 600;
  min-height: 3rem;
  line-height: 1.38731rem;
  /* 92% */
}

/* filters end here */

.operate-antd-select {
  border-radius: 0.3125rem;
  background: rgba(15, 15, 15, 0.5);
  width: 100%;
}

.piechart .apexcharts-tooltip {
  color: #fff !important;
}
