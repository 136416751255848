$theme-background-primary: #0f0f0f;
$theme-background-secondary: #3c3c3c;
$theme-color: #ff6a2f;
$text-color-primary: #3c3c3c;
$theme-border: #e1e1e1;
$text-color-secondary: #3c3c3c;
 
// for cards
$card-background:#262626;
$card-border: #3c3c3c;
$card-header-title: #0f0f0f;
$card-icon: #ff6a2f;
// for table
$table-background: #fcfcfc;
$table-text: #0f0f0f;
$table-header-text: #ffff;
$table-header-background: #3c3c3c;
