.migroot {
  margin: 1rem 2rem 2rem 2rem;
}

.cards1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-gap: 13px;
}
.cards2 {
  margin-top: 2rem;
}

.cards2 .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);
}

.cards1 .ant-card {
  border-radius: 9px;
  border: 1px solid rgba(255, 255, 255, 0.28);
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(255, 255, 255, 0) 468.03%
  );
  backdrop-filter: blur(5px);

  /* width: 15.1rem; */
}
.cards1 .ant-card .ant-card-body {
  padding: 10px;
  border-radius: 0 0 8px 8px;
}
.cards2 .cd {
  margin-bottom: 3rem;
}
.cards1 .ant-card .ant-card-head-title {
  color: #ff6a2f;

  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
}
.cards2 .ant-card .ant-card-head {
  border-bottom: none;
}
.cards2 .ant-row {
  display: flex;
  flex-flow: row wrap;
  min-width: max-content;
}
.cards2 .ant-card .ant-card-head-title {
  color: #ff6a2f;

  text-align: center;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1rem;
}
.flleft {
  float: left;
  color: white;
  font-size: 0.9rem;
  text-align: center;
}
.flright {
  float: right;
  color: white;
  font-size: 0.9rem;
  text-align: center;
}
.box1 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
}

.box2 {
  text-align: center;
  /* border: 1px solid beige; */
  padding: 1rem;
  margin-left: 12rem;
  color: #ff6a2f;
  background: rgba(255, 255, 255, 0.06);
  margin-right: 12rem;
  margin-top: 2rem;
}

.header1 {
  margin-bottom: 2rem;
}
.left {
  float: left;
  color: white;
}
.box2 .h6 {
  font-size: 2rem;
}
.right {
  float: right;
  margin-right: 20rem;
  color: white;
}
.cards1 .ant-card .ant-card-head {
  background-color: transparent;
  border: none;
  min-height: 3rem;
}
.cards .ant-form-item {
  margin-top: 1rem;
}
.selectst .ant-select-selection-placeholder {
  color: #fff !important;
}

h2.heading {
  color: #fff;

  font-family: Poppins;
  font-size: 1.75rem;
  font-style: normal;
  text-align: center;
  line-height: normal;
  margin: 2rem;
}
.box3 .ant-progress.ant-progress-show-info .ant-progress-outer {
  margin-inline-end: calc(-2em - 8px);
  padding-inline-end: calc(2em + 8px);
  margin-top: 2rem;
}
.box3 .ant-progress .ant-progress-bg {
  position: relative;
  background-color: #ffd380;
  border-radius: 100px;
  transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.box3 .ant-progress .ant-progress-inner {
  position: relative;
  display: inline-block;
  width: 100%;
  overflow: hidden;
  vertical-align: middle;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 100px;
  margin-top: 1.5rem;
}
p.ptag {
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 0rem;
  color: white;
  font-family: Poppins;
  font-size: 0.75rem;
}

.cd1 .apexcharts-yaxis-label {
  fill: white; /* Change to your desired color */
}

.donutchart .apexcharts-tooltip {
  background-color: white !important;
  border: 1px solid white !important;
  color: black !important;
}
.donutcharthover .apexcharts-donut-tooltip:hover {
  fill: red; /* Change to your desired color */
}
.box3 .ant-progress-line {
  position: relative;
  width: 100%;
  font-size: 14px;
  /* margin-inline-end: 8px; */
  margin-bottom: 8px;
  padding-left: 2rem;
  padding-right: 2rem;
}
.headings {
  color: #ff6a2f;
  margin-left: 1rem;
  margin-top: 1rem;
}
.text-white {
  /* margin-top: 1rem; */
}
.donut-chart {
  min-height: 310px;
}
.buttonmigrate {
  display: inline-block;
  padding: 8px 8px;
  background-color: gray;
  color: #fff;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-right: 1rem;
  margin-bottom: -0.75rem;
}
