.tabsH {
  display: flex;
  padding: 5px;
}

.tabsH button {
  border-bottom: 1.5px solid transparent;
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
  color: whitesmoke;
  padding: 3px 10px;
  width: 200px;
  font-size: 10.3px;
  font-weight: 600;
  text-transform: uppercase;
}

.activeFilter {
  opacity: 0.9 !important;
}

/* .incDec .incBtn {
  background: #adf3ad;
  border: 0;
  border-radius: 42px;
  opacity: 0.3;
  font-size: 12px;
  font-weight: 500;
  width: 20px;
  height: 20px;
}

.incBtn svg {
  position: relative;
  right: 0px;
  left: -2px;
  top: -1px;
  fill: green;
  height: 10px;
} */
/* 
.incDec .decBtn {
  background: #ff7a55;
  opacity: 0.3;
  border: 0;
  border-radius: 42px;
  font-size: 12px;
  font-weight: 500;
  width: 20px;
  height: 20px;
  margin: 0px 9px;
} */
/* 
.decBtn svg {
  position: relative;
  right: 0px;
  left: -2px;
  height: 10px;
  top: -1px;
  fill: rgba(255, 255, 255, 0.831);
} */

.tabsH .active {
  color: #ff8a5b;
  opacity: 0.8;
  transition: 0.3s;
  border-bottom: 1.5px solid #848484;
}

.caret-align {
  position: relative;
  right: 3px;
  text-align: end;
  top: 4px;
}

/* .caret-align svg {
  box-shadow: 0 0 1px #3b3b3be0;
  border-radius: 2px;
  color: #ff8a5b;
  width: 20px;
  height: 20px;
  padding: 4px;
  background: #ffffff;
  opacity: 0.8;
  transition: 0.3s;
  cursor: pointer;
} */

/* --------Table csss---------- */

.tablGraph {
  width: 100%;
}

.w140 {
  width: 140px;
}

.tablGraph td,
.tablGraph th,
.tablGraph tr {
  opacity: 0.9;
  color: white;
  font-size: 12px;
  padding-bottom: 5px;
}

.tablGraph tr {
  font-size: 12px;
  border-bottom: 1px dotted #413f3f;
}
.tablGraph tr:last-child {
  border-bottom: 0px dotted transparent;
}
.production-switch .ant-switch-inner {
  background-color: red !important;
}

.non-production-switch .ant-switch-inner {
  background-color: #098e3f !important;
}
.vertical-text {
  padding: 0;
  position: absolute;
  left: 0;
  font-weight: 500;
  font-size: 11px;
  bottom: 32%;
  cursor: default;
  color: white;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  height: 100%;
}
.text-red {
  color: red !important;
}
.font-500 {
  font-weight: 500;
}
