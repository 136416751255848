:root {
  --costimize_site_color: #433f3f;
}

.navheader-position-sticky {
  position: sticky;
  top: 0;
  z-index: 222;
}

.plat-navbar {
  background-color: #fff !important;
  padding: 0px 35px !important;
  box-shadow: none !important;
  /* border-bottom: 0.37px solid #848484; */
  z-index: 4 !important;
}

.menu-top-img {
  border-radius: 2px;
}

.plat-navbar-top-menu {
  padding: 0px 0px 0px 2px !important;
}

.menu-topicon-username {
  /* color: #000; */
  padding-left: 13px !important;
}

.dropdown-menu {
  right: 0;
  left: auto;
  /* color: #4f4f4f; */
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  border: 0;
  box-shadow: 0 2px 15px -3px rgb(0 0 0 / 7%), 0 10px 20px -2px rgb(0 0 0 / 4%);
  font-size: 0.875rem;
  /* padding: 10px 0px 18px 0px !important; */
  /* background-color: #ffffff; */
  background-color: transparent !important;
  /* background-color: #0f0f0f !important; */
  color: #fff !important;
}

.navbar-top-submenu {
  /* background-color: #fff !important; */
  background-color: #0f0f0f !important;
  color: #fff !important;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important;
}

.plat-right-menu {
  font-size: 15px;
  color: #1b5f9d;
}

/* .dropdown-menu{
  color:#848484 !important;
} */
a.navbar-top-submenu.dropdown-item.active,
a.navbar-top-submenu.dropdown-item:active {
  /* color: #16181b !important; */
  background-color: #fff !important;
  padding: 0.1rem 1rem !important;
}

.menu-icon-with-bg {
  width: 30px;
  height: 30px;
  color: #1b5f9d;
  padding: 2px;
}

.menu-icon-without-bg {
  width: 30px;
  height: 30px;
  color: #848484;
  background: transparent;
  padding: 2px;
  font-size: 30px;
}

.menu-admin-panel {
  background: #fff !important;
  border: none !important;
  color: #082648 !important;
  width: 84px !important;
  padding: 0px !important;
  height: 28px !important;
  margin-top: 4px !important;
  border-radius: 6px !important;
  margin-left: 7px !important;
  margin-right: 10px;
  font-size: 18px !important;
}

.plat-navbar-brand {
  padding: 0px !important;
  margin: 0px !important;
  cursor: pointer;
}

.menu-admin-panel:hover {
  background: #fff !important;
  border: none !important;
  color: #082648 !important;
  width: 84px !important;
  padding: 0px !important;
  height: 28px !important;
  margin-top: 4px !important;
}

.menu-admin-panel:active,
.menu-admin-panel.active {
  background: #fff !important;
  border: none !important;
  color: #082648 !important;
  width: 84px !important;
  padding: 0px !important;
  height: 28px !important;
  margin-top: 4px !important;
}

.plat-navbar-top-menu a.nav-link {
  padding: 3px !important;
}

/* costimize */
.costimize_header {
  background-color: var(--costimize_site_color) !important;
  box-shadow: none !important;
  padding-right: 1rem;
  z-index: 999 !important;
}

.admindashboard_header {
  background-color: #0f0f0f !important;
  /* background-color: #1c344c !important; */
  box-shadow: none !important;
  padding-right: 1rem;
  z-index: 999 !important;
}

.costimize-admin-panel {
  color: #fff !important;
  background: linear-gradient(261deg, #ff6a2f 26.19%, #ffd380 168.97%);
  /* background-color: #0f0f0f !important; */
  /* border: #FF6A2F 1px solid !important; */
  /* background: #fff !important; */
  border: none !important;
  /* color: var(--costimize_site_color) !important; */
  width: 84px !important;
  padding: 0px !important;
  height: 30px !important;
  margin-top: 4px !important;
  border-radius: 6px !important;
  margin-left: 3px !important;
}

.costimize-icon-with-bg {
  width: 30px;
  height: 30px;
  color: var(--costimize_site_color) !important;
  background: #fff;
  padding: 6px;
  border-radius: 7px;
}

.costimize-navbar-top-menu {
  padding: 0px 3px 0px 7px !important;
}

.costimize-navbar-top-menu a.nav-link {
  padding: 3px !important;
}

.costimize-global-menu-icon {
  width: 30px;
  height: 30px;
  color: #ff6a2f;
  /* color: #fff; */
  background: transparent;
  margin-left: 7px;
  padding: 2px;
  font-size: 30px;
}

.costimize-header-logo {
  margin-left: 35px !important;
}

.costimize_header {
  padding-right: 2rem;
}

/* added for cams */
.header-logo-name {
  color: white;
  font-weight: 600;
  font-size: 14px;
}

.cams_header {
  background-color: #0f0f0f;
}

.devops_header {
  background-color: #082648;
}

/* new navHeader */
.navHeader.navbar {
  /* height: 12vh; */
  padding: 0 5rem;
  box-shadow: none;
  /* background-color: white; */
  display: flex;
  justify-content: space-between;
}

.nav-header-logo-text {
  color: #222222;
  font-weight: 600;
  font-size: 20px;
  padding-left: 3px;
}

.navHeader.navbar span.signin {
  color: #07182b;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding: 0 1.5rem;
}

.navHeader.navbar button.signup-button {
  background-color: #07182b !important;
  /* color: #fbce01 !important; */
  font-style: normal;
  font-weight: 500;
  font-size: 15px !important;
  padding: 0.8rem 2rem;
  border-radius: 5px;
}

.navlink-signin {
  display: flex;
  align-items: center;
}

.header-avatar {
  background-color: "#082648";
  color: "#fff";
  margin-top: "-1px";
}
