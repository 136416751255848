.cardContainerBg {
  background: #1f1f1f;
  position: relative;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #4b4b4b;
}
.lineRight::after {
  content: "";
  background: #4b4b4b;
  position: absolute;
  height: 100%;
  width: 0.6px;
  right: 0;
  opacity: 0.7;
  top: 0;
  bottom: 0;
}
.ps {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}
.crdB p {
  font-size: 12px;
}

/* ------/card scss */
.crdGrpBt {
  width: 100%;
  position: absolute;
  bottom: -2px;
  right: 0;
  left: 0;
}
.usg-mon-diff-t {
  font-size: 9px;
  color: #12cf12;
}
.usmon {
  top: 6px;
  padding: 0;
  position: relative;
  left: 13px;
  color: #ff6a2f;
}
.t1 {
  margin-bottom: 3px;
  font-size: 14px;
  font-weight: 600;
}
.t2 {
  margin-bottom: 0;
  font-size: 9px;
  font-weight: 600;
}
.ptl-3 {
  padding-top: 10px !important;
  padding-left: 13px;
  margin: 0;
}

.mnH {
  padding: 0 !important;
  height: 75px;
}
.overflowHidden {
  overflow: hidden;
}
