@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
:root {
  --spp_site_color: #ff6a2f;
  --spp_background_color: #0f0f0f;
  --spp_lite_site_color: #e7e7e7;
  --white-color: #fff;
}
* {
  font-family: "Poppins" !important;
}
.header-title-spp {
  font-weight: 600;
  font-size: 2.4rem;
  color: #ff6a2f;
  padding: 2rem 2rem 1rem 1rem;
}
.spp-invoice {
  margin-right: 4% !important ;
  margin-left: 4% !important ;
  background-color: var(--spp_background_color);
}
.spp_bg {
  background-color: var(--spp_background_color);
}
.date-picker-container {
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;
  float: right !important;
}

.date-picker-container .ant-picker.date-picker {
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  ) !important;
  color: #fff !important;
  border: 1.078px solid var(--111, rgba(255, 255, 255, 0.2)) !important ;
  backdrop-filter: blur(5.390927791595459px);
  padding-bottom: 0;
  padding-top: 0;
}

.ant-picker .ant-picker-input > input,
.ant-picker .ant-picker-input > input:focus,
.ant-picker .ant-picker-input > input::placeholder {
  /* color: #ff6a2f;
  border-bottom: 3px solid #ff6a2f;
  border-color: #ff6a2f;
  margin: 0px 5px 0px 4px;
  text-align: center;
  font-size: 1.25rem;
  width: 10rem; */
  color: black;
}



.spp-invoice-form.ant-modal .ant-input,
.spp-invoice-form.ant-modal .datepicker,
.spp-invoice-form.ant-modal .ant-modal-title,
.spp-invoice-form.ant-modal .ant-select .ant-select-selector,
.spp-invoice-form.ant-modal .ant-form-vertical .ant-form-item-label > label {
  background-color: #1e1e1e !important;
}

.ant-picker-input:hover .ant-picker-clear {
  background-color: #202020;
}
.spp-invoice-form.ant-modal .ant-modal-content {
  border-radius: 0.5625rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(
      0deg,
      rgba(31, 31, 31, 0.94) 0%,
      rgba(31, 31, 31, 0.94) 100%
    ),
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    );
  box-shadow: -8px 11px 43px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}
.spp-actions-row .yes-button,
.spp-actions-row .yes-button:disabled {
  color: white;
  background-color: #ff6a2f;
  border: transparent;
  margin: 0;
}
.ant-picker-footer,
.ant-picker-footer > .ant-picker-today-btn,
.ant-picker-date-panel .ant-picker-header,
.ant-picker-year-panel .ant-picker-header,
.ant-picker-decade-panel .ant-picker-header,
.ant-picker-month-panel .ant-picker-header {
  background-color: #2e2e2e !important;
  color: #ff6a2f !important;
}
.ant-picker-date-panel .ant-picker-body,
.ant-picker-year-panel .ant-picker-body,
.ant-picker-decade-panel .ant-picker-body,
.ant-picker-month-panel .ant-picker-body {
  background-color: #202020 !important;
}

.spp-invoice-form::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 5px;
  height: 0.7rem;
}

.spp-invoice-form::-webkit-scrollbar {
  background-color: transparent;
  width: 7px;
}

.spp-invoice-form {
  padding: 0;
}
.ant-picker-date-panel .ant-picker-content th,
.ant-picker-date-panel .ant-picker-cell,
.ant-picker-year-panel .ant-picker-cell,
.ant-picker-decade-panel .ant-picker-cell,
.ant-picker-month-panel .ant-picker-cell {
  color: #fff !important;
}

.ant-picker-header .ant-picker-header-super-prev-btn,
.ant-picker-header .ant-picker-header-super-next-btn {
  color: #fff !important;
}
.ant-picker-dropdown
  .ant-picker-cell-in-view.ant-picker-cell-selected
  .ant-picker-cell-inner {
  background-color: #ff6a2f !important;
}
.date-picker {
  color: var(--spp_site_color) !important;
  border-radius: 5px;
  margin-left: 10px !important;
  font-size: 16px;
  padding: 5px;
  background-color: #fff !important;
  border: 1px solid #fff !important;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
  float: right !important;
}
.ant-input.search-text {
  width: 200px !important;
  height: 2.6rem;
  color: #848484;
  border-radius: 0.3125rem;
  border: 1px solid #787878;
}

.ant-input.search-text::placeholder {
  color: #848484;
}
.spp-actions-row {
  display: flex;
  justify-content: flex-end;
}

.spp_Table.ant-table-wrapper .ant-table-thead > tr > td {
  color: var(--white-color);
  text-align: center;
  border: none;
}

.spp_Table.ant-table-wrapper .ant-table-tbody > tr > td {
  background-color: #202020 !important;
  border-top: 0.474px solid #84848484;
  border-bottom: transparent !important;
}

.spp_Table .ant-pagination .ant-pagination-prev {
  border: 1px solid #848484;
  margin: 0px;
  padding-right: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 7px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.spp_Table .ant-pagination .ant-pagination-item {
  border: 1px solid #848484;
  margin: 0px;
  border-radius: 0px;
}
.spp_Table .ant-pagination .ant-pagination-next .ant-pagination-item-link,
.spp_Table .ant-pagination .ant-pagination-prev .ant-pagination-item-link {
  display: flex;
  align-items: center;
}
.spp_Table .ant-pagination .ant-pagination-next {
  border: 1px solid #848484;
  margin-right: 16px;
  padding-right: 7px;
  padding-left: 7px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.spp_Table .ant-table-pagination.ant-pagination {
  background-color: #202020;
  margin-bottom: 5em;
  margin-top: 0;
  padding-bottom: 16px;
  padding-top: 13px;
  border-bottom-left-radius: 7px;
  border-bottom-right-radius: 7px;
}

.spp_Table.ant-table-wrapper .ant-table-thead > tr > th {
  color: #fff;
  background: #2e2e2e;
  min-height: 10rem;
  backdrop-filter: blur(5px);
  border: none;
  text-align: center;
}

.spp_Table.ant-table-wrapper .ant-table {
  background-color: #202020;
  margin-top: 2.45rem;
  color: var(--devops_site_color);
  overflow-x: auto;
}
.ant-table-wrapper {
  /* min-width: 100%; */
}
.spp.spp_Table.ant-table-wrapper .ant-table {
  background-color: #fff !important;
  color: var(--spp_site_color) !important;
  overflow-x: auto !important;
}
.tab-btn {
  background-color: #202020 !important;
  border: none !important;
  box-shadow: none !important;
  color: var(--spp_site_color) !important;
}
.drop-down-list .ant-dropdown-menu {
  background-color: var(--spp_site_color) !important;
  color: #fff !important;
  width: 162px !important;
}

.drop-down-list .ant-dropdown-menu .ant-dropdown-menu-item {
  color: #fff !important;
}
.popupbtn {
  background-color: var(--spp_site_color) !important;
  box-shadow: 0px 6.07109px 6.07109px rgba(18, 31, 44, 0.15) !important;
  width: 150.53px !important;
  color: #fff !important;
}
.drop-down-list.ant-dropdown
  .ant-dropdown-menu
  .ant-dropdown-menu-item-divider {
  background-color: #fff !important;
}
.reset-btn {
  background-color: var(--spp_site_color) !important;
  margin-bottom: 5%;
  margin-left: 1%;
}

.spp-date {
  width: 100% !important;
}
.spp {
  width: 100% !important;
  overflow-x: auto !important;
}
.spp-loader {
  color: var(--spp_site_color) !important;
  width: 100% !important;
}
.spp-loader .ant-spin .ant-spin-dot-item {
  background-color: var(--spp_site_color) !important;
}
.right-aligned {
  text-align: right !important;
}
.download-btn {
  font-size: 21px !important;
  /* color:rgb(112, 21, 21); */
  color: #c70000 !important;
}
.view-text {
  font-size: 18px !important;
}
