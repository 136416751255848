.customer-form-step {
  background: #d9d9d9;
  color: #848484;
  position: absolute;
  left: 76%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.customer-form-step-popup {
  margin-top: -4rem;
}

.form-cover-step {
  width: 65%;
  position: relative;
}

.customer-form-step
  > .ant-steps-item-active
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background-color: #082648;
  border-color: #082648;
}

.create-user-but-d-flex
  > .ant-form-item-row
  > .ant-form-item-control
  > .ant-form-item-control-input
  > .ant-form-item-control-input-content {
  justify-content: space-between;
  display: flex;
}
.-content
  .customer-form-step
  > .ant-steps-item-active
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  line-height: 24px;
  font-weight: 600;
}

.customer-form-step
  > .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-content
  > .ant-steps-item-title {
  line-height: 24px;
  font-weight: 600;
}

.customer-form-step > .ant-steps-item-active::before {
  background-color: #082648 !important;
}

.customer-form-step
  > .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background-color: #082648;
  border-color: #082648;
  color: #fff !important;
}

.customer-form-step
  > .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-icon
  > .ant-steps-icon {
  color: #fff;
}

.step-form-body {
  margin-top: 6rem;
  border: 0.37px solid #ff6a2f;
  border-radius: 5px;
  padding: 4rem;
}

.form-button {
  float: right;
  background-color: #082648;
}
.form-button:hover {
  border-color: white;
}

.form-button:disabled {
  background-color: #082648 !important;
  float: right;
}

.form-info-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 29px;
  cursor: pointer !important;
}

.form-info-icon-user-create {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 30px;
  left: 0px;
  cursor: pointer !important;
}

.details-form-view {
  position: relative;
}

/* .form-info-popup > .ant-modal-content {
  background: #082648;
} */
.role-checkbox > .ant-checkbox > .ant-checkbox-inner {
  border: 2px solid #082648 !important;
}

.role-checkbox > .ant-checkbox > .ant-checkbox-inner:hover {
  border: 2px solid #082648 !important;
  background-color: #082648 !important;
}

.role-checkbox > .ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #ff6a2f !important;
}

/* .form-info-popup > .ant-modal-content > .ant-modal-close {
  color: #fff;
} */
.role-management-table
  > .ant-spin-nested-loading
  > .ant-spin-container
  > .ant-table
  > .ant-table-container {
  width: 100%;
  overflow-x: auto;
}

.role-head-title > .ant-form-item-row > .ant-form-item-label label {
  font-size: 18px;
}

.role-head-title {
  font-size: 18px;
  color: #ff6a2f;
}

.role-permission-head-title {
  font-size: 16px;
  color: #ff6a2f;
}

.role-permission-access {
  font-size: 12px;
  color: #fff;
}

.role-offering-head-title {
  font-size: 14px;
  color: #fff;
}

.role-offering-list-title {
  font-size: 12px;
  color: #fff;
}

.role-management-title {
  font-weight: 600;
  font-size: 22px;
  line-height: 100%;
  /* color: #082648; */
  color: #ff6a2f;
}

.role-body {
  padding: 3rem;
}

/* table */
.role-management-table thead {
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  ) !important;
}

.role-management-table thead th {
  border: 1px solid var(--111, rgba(255, 255, 255, 0.2)) !important;
  background: var(
    --11,
    linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.08) 0%,
      rgba(255, 255, 255, 0) 468.03%
    )
  ) !important;
  backdrop-filter: blur(5px);
  color: #ff6a2f !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  text-align: center !important;
  z-index: 0 !important;
}

.role-management-table thead th .order > .dropup > .caret {
  margin: 10px 0;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.role-management-table thead th .order > .dropdown > .caret {
  margin: 10px 0 10px 5px;
  color: #fff;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}

.role-management-table
  thead
  th
  > .react-bootstrap-table-sort-order.dropup
  > .caret {
  margin: 10px 6.5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  border-bottom: none !important;
}

.role-management-table thead th > .react-bootstrap-table-sort-order > .caret {
  margin: 10px 6.5px;
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
}

.role-management-table thead td {
  /* background-color: var(--costimize_site_color) !important; */
  color: #fff !important;
  border: none !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 100% !important;
  white-space: nowrap;
  z-index: 0 !important;
}

@media only screen and (max-width: 2560px) and (min-width: 2305px) {
}

@media only screen and (max-width: 2304px) and (min-width: 2161px) {
}

@media only screen and (max-width: 2160px) and (min-width: 1922px) {
}

@media only screen and (max-width: 1921px) and (min-width: 1681px) {
}

@media only screen and (max-width: 1680px) and (min-width: 1601px) {
}

@media only screen and (max-width: 1600px) and (min-width: 1471px) {
}

@media only screen and (max-width: 1470px) and (min-width: 1441px) {
}

@media only screen and (max-width: 1440px) and (min-width: 1367px) {
}

@media only screen and (max-width: 1366px) and (min-width: 1281px) {
}

@media only screen and (max-width: 1280px) and (min-width: 1081px) {
}

@media only screen and (max-width: 1080px) and (min-width: 1025px) {
}

@media only screen and (max-width: 1024px) and (min-width: 769px) {
  .form-cover-step {
    width: 80%;
  }

  .customer-form-step {
    left: 63%;
  }
}

@media only screen and (max-width: 768px) and (min-width: 427px) {
  .form-cover-step {
    width: 100%;
  }

  .customer-form-step {
    left: 50%;
  }
}

@media only screen and (max-width: 426px) and (min-width: 377px) {
  .form-cover-step {
    width: 100%;
  }

  .customer-form-step {
    left: 50%;
  }

  .step-form-body {
    margin-top: 20rem;
  }
}

@media only screen and (max-width: 376px) and (min-width: 321px) {
  .form-cover-step {
    width: 100%;
  }

  .customer-form-step {
    left: 50%;
  }

  .step-form-body {
    margin-top: 20rem;
  }
}

@media (max-width: 320px) {
  .form-cover-step {
    width: 100%;
  }

  .customer-form-step {
    left: 50%;
  }

  .step-form-body {
    margin-top: 20rem;
  }
}

.switch-width {
  width: 2%;
}
.ant-popover .ant-popover-inner {
  background-color: #2e2e2e;
}
.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-title {
  color: white;
}
.ant-popconfirm .ant-popconfirm-message .ant-popconfirm-description {
  color: white;
}

.ant-switch-inner {
  background: #ff6a2f;
}
