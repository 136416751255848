:root {
    --site_color: #1c344c;
}

.budget-projected-col {
    border-radius: 7px !important;
    /* width: 234px !important; */
    height: 184px;
    margin-bottom: 5px;
}
.budget-projected-box{
    background-color: #ECF4FF;
    height: 184px;
    margin: 5px 0px;
}
.budget-projected-month-box{
    background-color: #ECF4FF;
    height: 100px;
    margin: 5px 0px;
}
.budget-allocated-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    height: 39px;
    padding-top: 8px;
}
.budget-allocated-value {
    font-weight: 600;
    font-size: 24px;
    line-height: 100%;
    text-align: center;
    padding: 7px;
    margin: 0px;
}
.budget-box-cneter-icon {
    text-align: center;
    color: var(--site_color);
    font-size: 26px;
}
.budget-allocated-witget-content {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
}
/* .budget-projected-cost-col {
    width: 359px !important;
} */
.budget-projected-cost-box {
    background-color: #ECF4FF;
    height: 184px;
    margin: 5px 0px;
    border-radius: 7px !important;
}
.budget-cost-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    margin-bottom: 16px;
}
.budget-cost-value {
    text-align: center;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 100%;
    color:#FFF !important;
}
.budget-cost-value-heading {
    text-align: center;
    font-weight: 400;
    font-size: 12.24px;
    line-height: 100%;
    color:white !important;
}
.cost-left-box {
    margin-top: 33px !important;
}
.main-box {
    width: 100%;
    height: 20px;
    background: #e7e7e7;
}
.boby-box {
    width: 100%;
    background: #cb1515 !important;
    height: 100%;
}
.bar-main-div {
    display: flex;
}
.bar-per {
    padding: 5px;
}
.budget-project-month-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
}
.budget-table-list {
    margin-right: 20px;
    border: 1px solid #e1e1e1;
    padding: 31px 7px;
    border-radius: 9px;
    background-color: #f9fbff;
    position: relative;
}
.budget-allocated-table {
    margin-top: 30px;
    margin-right: 20px;
    border: 1px solid #e1e1e1;
    padding: 12px 7px;
    border-radius: 9px;
    background-color: #f9fbff;
    /* position: relative; */
}
.budget-mt {
    margin-top: -20px;
}
.budget-table-title {
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    top: -5%;
    left: 2%;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
}
.application-budget-table-title{
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    top: -3.5%;
    left: 2%;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
}
.table-right-icon-list {
    display: flex;
    float: right;
}
.budget-table-chart-icon {
    color: var(--site_color) !important;
    margin-top: 5px;
    cursor: pointer;
    font-size: 14px;
}
.table-coloum-chart-icon {
    color: #fff !important;
    margin-top: 5px;
    cursor: pointer;
    font-size: 19px;
}
.chart-down-value {
    margin-top: -38px;
}
.table-planned-actual {
    margin-top: 10px;
}
.instace-tracking-table-title {
    position: absolute;
    font-size: 15.24px;
    font-weight: 500;
    top: -3%;
    left: 2%;
    background: #fff;
    padding: 0px;
    color: var(--site_color);
}
@media only screen and (max-width: 1080px) and (min-width: 1025px) {
    .budget-allocated-value {
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        padding: 7px;
        margin: 0px;
    }
    .budget-allocated-content {
        font-weight: 500;
        font-size: 11px;
        line-height: 100%;
        text-align: center;
        height: 39px;
        padding-top: 8px;
    }
}
@media only screen and (max-width: 1024px) and (min-width: 769px) {
    .budget-allocated-value {
        font-weight: 600;
        font-size: 15px;
        line-height: 100%;
        text-align: center;
        padding: 7px;
        margin: 0px;
    }
    .budget-allocated-content {
        font-weight: 500;
        font-size: 10px;
        line-height: 100%;
        text-align: center;
        height: 39px;
        padding-top: 8px;
    }
}