.spendTrendContainerCard {
  background: #212121;
  padding: 12px 10px;
  border: 1px solid #4b4b4b;
  border-radius: 10px;
  height: 98%;
}

.fz-13 {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: #767575;
}
.text-orange {
  color: #de5f2d;
}
.custom-tooltip {
  font-size: 11px;
}

.i-align {
  position: relative;
  top: -1px;
}

.grp-next svg,
.grp-prev svg {
  fill: #ffffff;
  background: #de5f2d;
  border-radius: 4px;
  height: 17px;
  width: 17px;
  padding: 3px;
}

.arrow-container {
  left: 0px;
  position: absolute;
  top: -38px;
}
.arrow-container button {
  border: none;
  background: transparent;
  padding: 0 2px;
}
.arrow-container button:disabled {
  opacity: 0.4;
}
.daywise-spend-modal .ant-modal-content {
  background: #212121 !important;
  border: 1px solid #4b4b4b;
}
.daywise-spend-modal .ant-modal-title {
  background: #212121 !important;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.5px;
}
.daywise-spend-modal p {
  color: white;
  margin: 0;
  font-size: 12px;
}
.scroll-table tbody:first-of-type {
  display: block;
  max-height: 101px;
  overflow: auto;
}

.scroll-table tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}
.daywise .apexcharts-point-annotation-marker {
  cursor: pointer;
}
